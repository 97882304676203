const userAgreementContent = `
超级面试AI用户协议
更新日期：2024年3月4日
 
特别提示：
您在使用超级面试AI提供的各项服务之前，请您务必审慎阅读、充分理解本协议各条款内容，特别是以粗体标注的部分，包括但不限于免除或者限制责任的条款。如您不同意本服务协议及/或随时对其的修改，您可以主动停止使用超级面试AI提供的服务；您一旦使用超级面试AI提供的服务，即视为您已了解并完全同意本服务协议各项内容，包括超级面试AI对服务协议随时所做的任何修改，并成为我们的用户。
一、总则
1.1 用户应当同意本协议的条款并按照页面上的提示完成全部的注册程序。用户在进行注册程序过程中点击"同意"按钮即表示用户与超级面试AI达成协议，完全接受本协议项下的全部条款。
1.2 用户注册成功后，超级面试AI将给予每个用户一个用户账号，用户可以自主设置账号密码。该用户账号和密码由用户负责保管；用户应当对以其用户账号进行的所有活动和事件负法律责任。
1.3 当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，除超级面试AI平台的具体产品对帐户有单独的注册要求外，您可获得超级面试AI平台（网站及客户端）账号并成为超级面试AI用户，可通过超级面试AI帐户使用超级面试AI平台的各项产品和服务。
1.4为了方便您在超级面试AI产品中享有一致性的服务，如您已经在某一超级面试AI产品中登录超级面试AI账号，在您首次使用其他超级面试AI产品时可能同步您的登录状态。除账号信息外，此环节并不会额外收集、使用您的个人信息。如您想退出账号登录，可在产品设置页面退出登录。
1.5 您确认，在您开始使用超级面试AI服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则应获得监护人的知情同意，您及您的监护人应依照法律规定承担因此而导致的相应的责任。
二、注册信息和隐私保护
2.1 超级面试AI账号（即超级面试AI用户ID）的所有权归超级面试AI，用户按注册页面引导使用用户个人信息绑定的手机号或者微信号，阅读并同意本协议且完成全部注册程序后，即可获得超级面试AI账号并成为用户。对于用户的其他个人信息，超级面试AI在注册阶段保证不会收集，但用户个人可选择是否提供及时、详尽及准确的个人资料，来选择在超级面试AI中展现，所有在App中注册阶段或者在超级面试AI没有要求进行实名验证时填写的个人信息均为用户自愿展现，即在个人资料界面的所有信息，SpeakNow 不保证其他用户利用这些信息。且用户提交的账号名称、头像和简介等个人信息中不得出现违法和不良信息，经超级面试AI审核，如存在上述情况，超级面试AI将不予注册；同时，在注册后，如发现用户以虚假信息骗取账号名称注册，或其账号头像、简介等注册信息存在违法和不良信息的，超级面试AI有权不经通知单方采取限期改正、暂停使用、注销登记、收回等措施。
如果因实名验证信息不真实或更新不及时而引发的相关问题，超级面试AI不负任何责任。您可以通过超级面试AI账号设置页面查询、更正您的信息，用户应当通过真实身份信息认证账号，如若出现虚假信息或者出现违法和不良行为，超级面试AI有权终止对该用户的所有服务内容，冻结或者注销被标记为不良行为的用户。
2.2 超级面试AI账号包括帐户名称和密码，您可使用设置的帐户名称（包括用户名、手机号、微信）和密码登录；在账号使用过程中，为了保障您的账号安全基于不同的终端以及您的使用习惯，我们可能采取不同的验证措施识别您的身份。例如您的帐户在新设备首次登录，我们可能通过密码加校验码的方式识别您的身份，验证方式包括但不限于短信验证码、服务使用信息验证。
2.3 用户不应将其账号、密码转让、出售或出借予他人使用，若用户授权他人使用帐户，应对被授权人在该帐户下发生所有行为负全部责任。由于账号关联用户使用信息，仅当依法律法规、司法裁定或经超级面试AI同意，并符合超级面试AI规定的用户账号转让流程的情况下，方可进行账号的转让。
2.4 因您个人原因导致的账号信息遗失，如需找回超级面试AI账号信息，请按照超级面试AI账号找回要求提供相应的信息，并确保提供的信息合法真实有效，若提供的信息不符合要求或账号的唯一凭证不再有效，无法通过超级面试AI安全验证，因存在账号被盗等安全风险导致无法判断账号归属，超级面试AI有权拒绝提供账号找回服务，并视情况采取冻结账号、封禁账号等措施。您超级面试AI账号下绑定的手机号如因您个人原因停止使用，请尽快更改超级面试AI账号的绑定手机，否则如遇运营商二次放号，二次放号的用户可以通过您原来的手机号进行验证登录，可查看到您的全部账号信息及产品使用信息，您的超级面试AI账号将无法继续使用，账号内个人信息也有泄露的风险，请您务必及时更换绑定手机，如因未及时换绑手机号导致账号丢失或个人信息泄露的责任由您自行承担，超级面试AI有权拒绝提供账号找回服务，并视情况采取冻结账号、封禁账号等措施。
2.5 在需要终止使用超级面试AI账号服务时，符合以下条件的，您可以申请注销您的超级面试AI账号。
（1）您仅能申请注销您本人的账号，并依照超级面试AI的流程进行注销；
（2）您仍应对您在注销账号前且使用超级面试AI服务期间的行为承担相应责任，同时超级面试AI仍可保存您注销前的相关信息；
（3）注销成功后，账号信息、个人身份证信息、交易记录、会员权益等将无法恢复或提供；
2.6 为了防止资源占用，如您连续一年未使用您的超级面试AI账号或未通过超级面试AI认可的其他方式登录过您的超级面试AI帐户，超级面试AI有权对该帐户进行注销，您将不能再通过该账号登录名登录本网站或使用相关服务。如该帐户有关联的理财产品、待处理交易或余额，超级面试AI会在合理范围内协助您处理，请您按照超级面试AI提示的方式进行操作。
2.7 为了满足相关法律法规的要求和保障您的账号安全，尤其是您在进行账号解绑、注销等敏感操作时，我们会将您提交的个人身份信息发送至银行、运营商等机构进行验证查询，以核实您身份的真实性。
三、使用规则
3.1 用户在使用超级面试AI的服务时，必须遵守《网络安全法》等中华人民共和国相关法律法规的规定，用户应同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为:
（1）上载、展示、张贴、传播或以其它方式传送含有下列内容之一的信息： 
1）反对宪法所确定的基本原则的；
2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
3）损害国家荣誉和利益的；
4）煽动民族仇恨、民族歧视、破坏民族团结的；
5）破坏国家宗教政策，宣扬邪教和封建迷信的；
6）散布谣言，扰乱社会秩序，破坏社会稳定的；
7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
8）侮辱或者诽谤他人，侵害他人合法权利的；
9）含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；
10）含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；
（2）不得为任何非法目的而使用网络服务系统；
（3）不利用超级面试AI的服务从事以下活动：
 1）未经允许，进入计算机信息网络或者使用计算机信息网络资源的；
 2）未经允许，对计算机信息网络功能进行删除、修改或者增加的；
 3）未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；
 4）故意制作、传播计算机病毒等破坏性程序的；
 5）其他危害计算机信息网络安全的行为。
（4）其他侵犯其他用户或他人合法权利、干扰超级面试AI正常运营或损害超级面试AI合法利益的行为。
3.2 用户违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，您同意赔偿超级面试AI与合作公司、关联公司，并使之免受损害。对此，超级面试AI有权视用户的行为性质，采取包括但不限于删除用户发布信息内容、暂停使用许可、终止服务、限制使用、回收超级面试AI账号、追究法律责任等措施。对恶意注册超级面试AI账号或利用超级面试AI账号进行违法活动、捣乱、骚扰、欺骗、其他用户以及其他违反本协议的行为，超级面试AI有权回收其账号。同时，超级面试AI会视行政或司法部门的要求，协助调查。
3.3 用户不得对本服务任何部分或本服务之使用或获得，进行复制、拷贝、出售、转售或用于任何其它商业目的。
3.4 用户须对自己在使用超级面试AI服务过程中的行为承担法律责任。用户承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在超级面试AI首先承担了因用户行为导致的行政处罚或侵权损害赔偿责任等民事责任后，用户应给予超级面试AI等额的赔偿，对于超级面试AI受到的其他非金钱性损失，包括但不限于声誉损害，用户应当负责消除影响并合理赔偿。
3.5 用户在使用超级面试AI服务时遵守以下互联网底线：
1）法律法规底线
2）社会主义制度底线
3）国家利益底线
4）公民合法权益底线
5）社会公共秩序底线
6）道德风尚底线
7）信息真实性底线
 
四、服务内容
4.1 超级面试AI网络服务的具体内容由超级面试AI根据实际情况提供。
4.2 除非本服务协议另有其它明示规定，超级面试AI所推出的新产品、新功能、新服务，均受到本服务协议之规范
4.3 为使用本服务，您必须能够自行经有法律资格对您提供互联网接入服务的第三方，进入国际互联网，并应自行支付相关服务费用。此外，您必须自行配备及负责与国际联网连线所需之一切必要装备，包括计算机、数据机或其它存取装置。
4.4 鉴于网络服务的特殊性，用户同意超级面试AI有权不经事先通知，随时变更、中断或终止部分或全部的网络服务（包括收费网络服务）。超级面试AI不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。
4.5 免责声明：因以下情况造成网络服务在合理时间内的中断，超级面试AI无需为此承担任何责任；
（1）超级面试AI需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，超级面试AI保留不经事先通知为维修保养、升级或其它目的暂停本服务任何部分的权利。
（2）因台风、地震、洪水、雷电或恐怖袭击等不可抗力原因；
（3）用户的电脑软硬件和通信线路、供电线路出现故障的；
（4）因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因、第三方服务瑕疵或政府行为等原因尽管有前款约定，超级面试AI将采取合理行动积极促使服务恢复正常。
4.6 本服务或第三人可提供与其它国际互联网上之网站或资源之链接。由于超级面试AI无法控制这些网站及资源，您了解并同意，此类网站或资源是否可供利用，超级面试AI不予负责，存在或源于此类网站或资源之任何内容、广告、产品或其它资料，超级面试AI亦不予保证或负责。因使用或依赖任何此类网站或资源发布的或经由此类网站或资源获得的任何内容、商品或服务所产生的任何损害或损失，超级面试AI不承担任何责任。
4.7 用户明确同意其使用超级面试AI网络服务所存在的风险将完全由其自己承担。用户理解并接受下载或通过超级面试AI服务取得的任何信息资料取决于用户自己，并由其承担系统受损、资料丢失以及其它任何风险。超级面试AI对在服务网上得到的任何商品购物服务、交易进程、招聘信息，都不作担保。
4.8 用户须知：超级面试AI提供的各种挖掘推送服务中，推送给用户曾经访问过的网站或资源之链接是基于机器算法自动推出，超级面试AI不对其内容的有效性、安全性、合法性等做任何担保。
4.9 超级面试AI有权于任何时间暂时或永久修改或终止本服务（或其任何部分），而无论其通知与否，超级面试AI对用户和任何第三人均无需承担任何责任。
4.10 违约处理
针对您违反本协议或其他服务条款的行为，公司有权独立判断并视情况采取预先警示、拒绝发布、立即停止传输信息、24小时内删除评论、视频、图文等内容、短期禁止发言、限制账号部分或者全部功能直至终止提供服务、永久关闭账号等措施。公司有权公告处理结果，且有权根据实际情况决定是否恢复相关账号的使用。对涉嫌违反法律法规、涉嫌违法犯罪的行为，公司将保存有关记录，并有权依法向有关主管部门报告、配合有关主管部门调查、向公安机关报案等。对已删除内容公司有权不予恢复。
4.11 终止服务
您同意超级面试AI得基于其自行之考虑，因任何理由，包含但不限于长时间（超过一年）未使用，或超级面试AI认为您已经违反本服务协议的文字及精神，终止您的密码、账号或本服务之使用（或服务之任何部分），并将您在本服务内任何内容加以移除并删除。您同意依本服务协议任何规定提供之本服务，无需进行事先通知即可中断或终止，您承认并同意，超级面试AI可立即关闭或删除您的账号及您账号中所有相关信息及文件，及/或禁止继续使用前述文件或本服务。此外，您同意若本服务之使用被中断或终止或您的账号及相关信息和文件被关闭或删除，超级面试AI对您或任何第三人均不承担任何责任。
五、知识产权和其他合法权益（包括但不限于名誉权、商誉权）
5.1 用户专属权利
超级面试AI尊重他人知识产权和合法权益，呼吁用户也要同样尊重知识产权和他人合法权益。若您认为您的知识产权或其他合法权益被侵犯，请按照以下说明向超级面试AI提供资料∶请注意：如果权利通知的陈述失实，权利通知提交者将承担对由此造成的全部法律责任（包括但不限于赔偿各种费用及律师费）。如果上述个人或单位不确定网络上可获取的资料是否侵犯了其知识产权和其他合法权益，超级面试AI建议该个人或单位首先咨询专业人士。
为了超级面试AI有效处理上述个人或单位的权利通知，请使用以下格式（包括各条款的序号）：
1. 权利人对涉嫌侵权内容拥有知识产权或其他合法权益和/或依法可以行使知识产权或其他合法权益的权属证明；
2. 请充分、明确地描述被侵犯了知识产权或其他合法权益的情况并请提供涉嫌侵权的第三方网址（如果有）。
3. 请指明涉嫌侵权网页的哪些内容侵犯了第2项中列明的权利。
4. 请提供权利人具体的联络信息，包括姓名、身份证或护照复印件（对自然人）、单位登记证明复印件（对单位）、通信地址、电话号码、传真和电子邮件。
5. 请提供涉嫌侵权内容在信息网络上的位置（如指明您举报的含有侵权内容的出处，即：指网页地址或网页内的位置）以便我们与您举报的含有侵权内容的网页的所有权人/管理人联系。
6. 请在权利通知中加入如下关于通知内容真实性的声明： “我保证，本通知中所述信息是充分、真实、准确的，如果本权利通知内容不完全属实，本人将承担由此产生的一切法律责任。”
7. 请您亲笔签署该文件，如果您是依法成立的机构或组织，请您加盖公章。 请您把以上资料和联络方式发往以下邮箱地址：juzhou001@gmail.com
一旦收到符合上述要求之通知，超级面试AI将在合理时间内删除涉嫌侵权的作品。如不符合上述条件，超级面试AI会请您补充提供相应信息，且暂不采取包括删除等相应措施。
5.2 对于用户通过超级面试AI服务（包括但不限于社区论坛等）上传到超级面试AI网站上可公开获取区域的任何内容，视为同意超级面试AI在收到投诉方的权利通知时视情况采取相应措施，超级面试AI不因此承担任何法律责任，同时，用户同意超级面试AI在全世界范围内具有免费的、永久性的、不可撤销的、非独家的和完全再许可的权利和许可，以使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示此等内容（整体或部分），和/或将此等内容编入当前已知的或以后开发的其他任何形式的作品、媒体或技术中。
5.3 超级面试AI拥有本网站内所有资料的版权。任何被授权的浏览、复制、打印和传播属于本网站内的资料必须符合以下条件：
所有的资料和图象均以获得信息为目的； 所有的资料和图象均不得用于商业目的； 所有的资料、图象及其任何部分都必须包括此版权声明； 本网站所有的产品、技术与所有程序及其他信息（包括文字、图标、图片、照片、音频、视频、图表、色彩组合、版面设计等）均属于超级面试AI知识产权，在此并未授权。“超级面试AI”及相关图形等为超级面试AI的注册商标。 未经超级面试AI许可，任何人不得擅自（包括但不限于：以非法的方式复制、传播、展示、镜像、上载、下载）使用。否则，超级面试AI将依法追究法律责任。
5.4 您知悉、理解并同意，为使您的作品得到更好的分享及推广，提高其传播价值及影响力，您通过超级面试AI创作平台上传、发布或传输的内容（包括但不限文字，图像，视频等各种形式的内容及其中包括的音乐、声音、台词、视觉设计、对话等所有组成部分），授予本公司及其关联方、控制公司、继承公司一项全球范围内、免费、非独家、可再许可（通过多层次）的权利（包括但不限于复制权、翻译权、汇编权、信息网络传播权、改编权及制作衍生品、表演和展示的权利等），上述权利的使用范围包括但不限于在当前或其他网站、应用程序、产品或终端设备等使用。您在此确认并同意，超级面试AI软件及相关服务、公司和/或公司品牌有关的任何宣传、推广、广告、营销和/或研究中使用和以其他方式开发内容（全部或部分）的权利和许可。为避免疑义，您理解并同意，上述授予的权利包括使用、复制和展示您拥有或被许可使用并植入内容中的个人形象、肖像、姓名、商标、服务标志、品牌、名称、标识和公司标记（如有）以及任何其他品牌、营销或推广资产、物料、素材等的权利和许可。基于部分功能的特性，您通过超级面试AI创作平台发布的内容（包括但不限文字，图像，视频等）可供其他用户使用“超级面试AI”软件创作及发布相关内容时使用。
六、青少年用户特别提示
青少年用户必须遵守全国青少年网络文明公约：要善于网上学习，不浏览不良信息；要诚实友好交流，不侮辱欺诈他人；要增强自护意识，不随意约会网友；要维护网络安全，不破坏网络秩序；要有益身心健康，不沉溺虚拟时空。并且超级面试AI面向于青少年用户有额外的优惠服务，请确保用户在自身有消费能力并且有消费意识或者在监护人的看管下选择我们的收费服务；在用户选择使用我们的收费服务时，超级面试AI将认为用户已经在满足上述条件下来使用我们的服务。
七、其他
7.1 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。
7.2 如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向 任何一方均可向中华人民共和国北京市海淀区人民法院提起诉讼解决。
7.3 超级面试AI未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。
7.4 如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。
7.5 隐私政策、相关平台规则均与本协议不可分割且具有同等法律效力。
请您在发现任何违反本服务协议以及其他任何单项服务的服务条款、超级面试AI各类公告之情形时，通知超级面试AI。您可以通过如下联络方式同超级面试AI联系：juzhou001@gmail.com


`;

export default userAgreementContent;
