const agreementContent = `
超级面试AI 会员服务协议
更新日期：2024年7月12日
 
【首部及导言】
欢迎您使用超级面试AI付费会员服务！
为使用超级面试AI付费会员服务（简称为：本服务），您应当阅读并遵守《超级面试AI会员服务协议》（简称为：本协议）。请您务必审慎阅读、充分理解各条款内容，特别是以黑体加粗或其他合理方式提示您注意的条款，该等条款可能与您的权益存在重大利害关系，包括但不限于相关约定橘洲科技责任的条款、约定双方权利义务的条款、约定争议解决方式及司法管辖条款等，请您留意重点阅读。如果您认为该等条款可能导致您在特定情况下部分或全部利益或权利受损，请您在确认同意本协议之前或在使用本服务之前务必再次阅读前述条款，并在您自愿接受该等条款的前提下使用本服务。
除非您已阅读并接受本协议所有条款，否则您无权使用本服务。当您确认接受本协议或开始使用本服务（包括但不限于点击同意、开通或续费、进入购买程序、或您对本服务的任何购买/赠送、接受赠与、参加活动等获取行为、或您进行或参与任何与本服务相关的交易或活动、采用任何其他形式的确认操作等任何使用行为）时即视为您已阅读并同意本协议的约束，自愿接受并遵守本协议的全部约定。
如果您未满18周岁，请在法定监护人的陪同下阅读并确认本协议内容，请您特别注意未成年人使用条款。
 
一、【协议的定义及说明】
1.1【协议适用主体范围】
本协议由您与北京橘洲科技有限公司（简称为：橘洲科技）共同缔结，具有合同效力。
1.2【本协议】
1.2.1本协议为《超级面试AI用户协议》、《超级面试AI隐私政策》的补充协议，是以上协议不可分割的组成部分，与其构成统一整体。本协议与上述协议存在冲突的，以本协议为准。
1.2.2本协议内容同时包括橘洲科技已经发布的或将来不时发布/更新的关于本服务的相关协议、业务规则等内容。上述内容一经正式发布/更新即生效，为本协议不可分割的组成部分，您同样应当遵守。本协议正文与前述规则存在冲突的，以本协议正文为准。除另行明确声明外，您使用付费会员服务均受本协议约束。
1.3【知识产权】
1.3.1橘洲科技是超级面试AI的知识产权权利人。超级面试AI的一切著作权、商标权、专利权、商业秘密等知识产权及其他合法权益，以及与超级面试AI相关所有的信息内容（包括文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受中华人民共和国法律法规和中华人民共和国缔结的相应国际条约保护，橘洲科技享有上述知识产权和合法权益，但相关权利人依照相关法律规定应享有的权利除外。
1.3.2未经橘洲科技书面同意，您不得以任何方式将超级面试AI（包括超级面试AI整体及超级面试AI涉及的所有内容、组成部分或构成要素）进行任何商业性使用。超级面试AI可能涉及第三方知识产权，如该等第三方对您基于本协议在超级面试AI中使用该等知识产权有要求的，橘洲科技将以适当的方式向您告知该要求，您应当一并遵守。
 
二、【关于本服务】
超级面试AI付费会员服务，指用户按照超级面试AI的指定方式支付一定服务费用之后，用户可以享有由超级面试AI提供的一定的特权优惠或会员活动。具体服务内容以超级面试AI提供的为准，包括但不限于额外角色使用权、额外口音、更高音质等方面特权的服务。为避免歧义，尽管采用“付费”之表述，为丰富用户体验，橘洲科技可能会通过“赠送”、“兑换”等方式向符合条件的用户提供特定期限的超级面试AI付费会员服务，该等用户所享受的服务也属于“超级面试AI付费会员服务”。本协议下又称“付费会员服务”或“本服务”。
同时，超级面试AI也可能会根据用户的需求、产品及服务类型的变化、业务发展变化等，变更会员名称、对现有服务种类进行调整以及不断推出新的服务种类。超级面试AI也可能会在不同时间推出具体不同的服务内容，以不断完善、优化本服务。具体服务种类及服务内容以相关服务页面公布、实际提供的内容为准，请您务必仔细阅读。当您采用接受服务的形式进行下一步操作或继续使用本服务，即表明您已经接受全部调整。若您不同意橘洲科技的调整，应立即停止使用本服务。您所享有的本服务的具体内容可能会因为级别、是否年费、开通期限、您选择的具体服务类别等因素而有不同，具体以相关服务页面公布、实际提供的内容为准。
2.1【服务限制】
基于版权方的要求，您在开通本服务期间添加的学习资源，可能有部分学习资源仅可在您使用的下载终端设备（即指添加学习资源时使用的终端设备）本地进行使用，不能进行复制或拷贝至其他终端设备。
2.2【虚拟产品】
2.2.1橘洲科技对其提供的虚拟产品享有自主定价权利，并有权根据自身运营需要予以变更。请您在购买或以其他合法方式获取时特别关注相关虚拟产品的价格以及相关通知和公告。
2.2.2基于虚拟产品的性质和特征，除法律法规规定外，您所获取的虚拟产品不能够进行退货、换货、兑换现金，亦不得用于商业领域，如买卖、置换、抵押等。一切通过非官方公布渠道取得的虚拟产品及其衍生服务均不获保护，橘洲科技有权单方收回并终止相应服务。
2.3【广告服务】
2.3.1您理解并同意，您在使用超级面试AI的过程中，将可能看到以各种方式投放的商业性广告或其他类型的商业信息（形式包括但不限于:【开屏广告、通栏广告、按钮广告、浮动广告、弹窗广告、暂停广告等】以及未来根据橘洲科技业务安排而可能出现的新兴广告形式）。橘洲科技将尽量降低上述广告服务对您使用体验的影响。您理解并同意，橘洲科技可以通过站内信、手机短信、网站公告或其他方式向您发送推荐的学习资源内容、会员福利、优惠活动等信息。
2.3.2会员可能享有一定程度的广告减免特权（下称“广告特权”）。尽管有前述约定，对于该部分会员，并不会完全的消除所有广告和商业信息，您对此表示理解同意。橘洲科技可能会开展各项活动，您可以通过参加活动以您享有的广告特权或其他会员权益兑换会员时长或其他福利，具体以相关活动页面的说明为准。
2.4【关于收费】
2.4.1本服务是橘洲科技提供的收费服务，您须在按照本服务的收费标准支付相应费用后，方可使用本服务。此外，您可以通过参与活动（包括但不限于兑换码兑换、积分兑换等）等橘洲科技认可的官方方式享有、使用本服务。
2.4.2橘洲科技可能根据本服务的整体规划，对本服务相关权益细则、收费标准、方式等进行修改和变更，前述修改、变更，橘洲科技将在相应服务页面进行展示。您若需要获取、使用本服务，请先提前了解清楚当时关于本服务的收费标准、方式等信息。当您采用接受服务的形式进行下一步操作或继续使用本服务，即表明您已经接受本协议的全部修改。
2.4.3橘洲科技会根据实际情况，对不同阶段或活动中充值、续费的付费会员给予不同的优惠，具体优惠政策以橘洲科技相关服务页面的信息为准。
2.4.4您在此理解并同意因参加活动不同，付费会员将享受不同的增值服务、资费或福利赠送。
2.5您理解并同意，橘洲科技向您提供的服务，可能包含橘洲科技经合法权利人授权而向您提供的相关服务，因此，由于该权利人对橘洲科技授权时间、范围、限制等的约束，所以橘洲科技会对开通相关服务用户所在的地区以及用户具体使用服务的时间、内容、地域范围、终端设备等做一定限制，可能会导致您在一定的地区或时间或终端设备等情况下无法获得相关服务，或无法获得所有展示内容的服务。对于因为第三方原因造成您无法享有相关服务的，橘洲科技无需承担任何责任。
2.6【付费会员额外付费】
2.6.1您理解并同意，您成功开通付费会员服务后，在您享有的会员权益之外，仍有内容需要您额外付费，包括但不限于：服务使用加量包、创作者的学习资源等
2.6.2其他类型需要额外付费的内容，请您在相关页面关注付费说明。前述需要额外付费的服务内容，超级面试AI会以显著标注在相应页面向您作出提示，请您在购买额外付费内容前阅读页面说明，并根据自身需求是否选择购买。在您付费成功后，该等额外付费内容可能存在相应的使用和/或有效期限制（具体以购买页面说明为准）。同时您理解并同意，额外付费内容中的部分内容在一定期限后，橘洲科技有权提供给其他会员/用户免费使用
 
三、【权利义务】
3.1 【服务开通】
3.1.1如您希望使用付费会员服务的，需先登录您的超级面试AI账号，或注册超级面试AI账号并登录。在成功登录超级面试AI账号的基础上，申请开通付费会员服务。如您选用其他第三方账号登录超级面试AI的，您应保证第三方账号的稳定性、真实性以及可用性，如因第三方账号原因（如第三方账号被封号等）致使您无法登录超级面试AI的，您应与第三方账号的所属公司联系。您在使用付费会员服务时登录的超级面试AI账号是橘洲科技确认您身份的唯一依据。
 3.1.2您应通过橘洲科技已有和未来新增的官方指定渠道获得付费会员服务。在您具体使用某种方式开通付费会员服务时，须仔细核对账号、绑定手机号、开通服务类型、期限、价格、所享有的会员权益等具体信息，并确认同意相关的协议和使用方法。如因您个人原因造成账号充值错误、服务或时长等开通错误，橘洲科技不予退还已收取的费用。本服务开通完成后，不可进行转让或要求退费，本协议另有约定或法律法规规定的除外。
3.2【账号管理及使用】
3.2.1出于向您提供服务的必要性，您在申请开通付会员服务过程中，需要填写一些必要的信息，请保持这些信息的真实性并及时更新，以便橘洲科技向您提供帮助，或更好地为您提供服务。若您的信息不完整或不准确，则可能无法使用服务或在使用过程中受到限制。
3.2.2您应确保账号注册资料的真实性，如因您的个人信息不真实而引起对您的不利后果，橘洲科技不承担任何责任。若您使用其他用户的账号开通付费会员，则橘洲科技有权停止该账号的服务功能，并取消该账号的付费会员资格而无须给予任何补偿和退还费用。
3.2.3橘洲科技特别提醒您应妥善保管您的账号和密码。当您使用完毕后，应安全退出。因您保管不善可能导致遭受盗号、密码失窃、虚拟财产被使用/转移，责任由您自行承担。
3.3 【服务期限】
3.3.1本服务的服务期限以您自行选择并支付相应服务费用的期限为准
3.3.2 本服务的服务开通最高期限会受到一定限制，具体以超级面试AI制定的的规则为准。
3.4 【行为规范】
3.4.1您在使用本服务时不得利用本服务从事以下行为，包括但不限于：
（1）发布、传送、传播、储存违反国家法律、危害国家安全统一、社会稳定、违反公序良俗、社会公德以及侮辱、诽谤、淫秽、暴力的内容；
（2）发布、传送、传播、储存侵害橘洲科技以及任何第三方名誉权、肖像权、知识产权、财产权、商业秘密等合法权利的内容；
（3）虚构事实、隐瞒真相以误导、欺骗他人；
（4）发表、传送、传播广告信息及垃圾信息；
（5）进行任何侵犯橘洲科技以及任何第三方的知识产权、商业秘密、财产权、名誉权等合法权利的行为；
（6）从事其他违反法律法规、政策及公序良俗、社会公德等的行为。
3.4.2【对自己行为负责】
 您充分了解并同意，您必须为自己注册/登录账号下的一切行为负责，包括但不限于您所发表的任何内容以及由此产生的任何后果。您应对本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。
 3.5【服务的变更、中止或终止】 
3.5.1本服务的中止或终止包含如下情况：
3.5.1.1用户主动中止或终止，包括但不限于到期未进行续费等；
3.5.1.2因为用户违约行为，橘洲科技主动中止或终止服务的；
3.5.1.3因国家或相关部门要求或发生不可抗力事件时，橘洲科技中止或终止服务的；
3.5.1.4其他根据法律法规应中止或终止服务的。
3.5.2 您充分了解并同意，由于互联网服务的特殊性，超级面试AI可能会按照相关法规、双方约定或在其他必要时，中止或终止向您提供本服务，届时，超级面试AI会依法保护您的合法权益。
3.5.3 您充分了解并同意，因版权合约限制，对于版权方要求的专案营销方式，橘洲科技将应版权方要求另行其他方式提供增值服务并收取费用，不计入付费会员服务中。由于版权合约约束，超级面试AI会对开通付费服务用户所在的地区以及用户具体使用服务的时间、地域、内容范围等做出一定限制，可能会导致用户在一定的地区或时间等情况下无法获得相关服务。
3.5.4 您充分了解并同意，橘洲科技因版权原因需调整、更改或下线有关作品内容，橘洲科技有权根据实际情况，在不通知用户的情况下中断或终止向用户提供的一项或多项作品内容，对于因为前述原因或其他原因造成用户无法获取所需作品内容，橘洲科技不承担任何责任且无需给予任何形式的补偿和退费。
3.5.5中止或终止后的处理
（1）除法律规定的责任外，橘洲科技对本服务的中止或终止对用户和任何第三人不承担任何责任；
（2）除本协议特别指定外，已收取的费用不予退还；
（3） 正在进行的平台交易，橘洲科技将根据情况合理判断，是否继续进行或终止相关交易，并视情况决定是否进行退费或扣除相关费用作为违约金。
    3.6【付费会员资格的有关规定】
    3.6.1 付费会员服务仅限于申请账号自行使用，且不能在不同的超级面试AI账号之间转移。
    3.6.2在已开通的付费会员有效期内，若您中途主动取消或终止付费会员资格的，将不会获得付费会员费用的退还。
3.6.3付费会员资格取消/终止后，您不能再参加由超级面试AI组织的会员活动并不可再享有由超级面试AI提供的各项特权服务及增值服务，即不再享有付费会员服务。
3.7若您以非法的方式或不正当手段或使用非橘洲科技所指定的渠道获得付费会员服务或者利用不同渠道的漏洞进行恶意退费的，橘洲科技有权立即终止您的付费会员服务并无须给予任何补偿、赔偿，无须退还开通费用。非官方渠道包括但不限于电商平台代充、私人代充或其他未经授权渠道。由于您使用非官方渠道而导致的各类损失，由您与该非官方渠道解决，橘洲科技概不负责。
3.8您在使用超级面试AI付费会员服务过程中，应遵守包括但不限于《中华人民共和国著作权法》、《中华人民共和国网络安全法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《信息网络传播权保护条例》等有关计算机及互联网规定的法律、法规；应遵守公共秩序，尊重社会公德，不得危害网络安全，不得利用网络从事危害国家安全、荣誉和利益，煽动颠覆国家政权、推翻社会主义制度，煽动分裂国家、破坏国家统一，宣扬恐怖主义、极端主义，宣扬民族仇恨、民族歧视，传播暴力、淫秽色情信息，编造、传播虚假信息扰乱经济秩序和社会秩序，以及侵害他人名誉、隐私、知识产权和其他合法权益等活动。在任何情况下，橘洲科技一旦合理地认为您存在上述行为的，可以在任何时候，不经事先通知终止向您提供服务。
 
四、付费会员介绍及使用规则
（一）永久会员
-- 会员特权：解锁更多APP内高质量服务，具体可见第二节第三条超级面试AI 会员权益。请务必注意，付费会员主要权益是解锁更多使用模块，并不代表可以无限制使用我们的服务，如若我们提供的额度不满足用户个人的使用需求，请根据具体个人使用需求来加量购买。
永久会员服务协议：
-- 隐私协议: 
-- 用户协议: 
（二）自动订阅服务说明
 1.订阅服务：
· 超级面试AI 普通会员迷你面试包
· 超级面试AI 普通会员经典面试包
· 超级面试AI 普通会员超值面试大礼包
· 超级面试AI pro会员高级迷你包
· 超级面试AI pro会员高级经典包
· 超级面试AI pro会员超值高级面试礼包
 2.订阅价格：
· 超级面试AI 普通会员迷你面试包，包含30000 token使用权限,有效期为365天, 产品价格为99元
•超级面试AI 普通会员经典面试包，包含80000 token使用权限,有效期为365天, 产品价格为249元
•超级面试AI 普通会员超值面试大礼包，包含240000 token使用权限,有效期为365天, 产品价格为660元
•超级面试AI pro会员高级迷你包，包含30000 token使用权限,以及高级会员特殊权限，有效期为365天 产品价格为150元
•超级面试AI pro会员高级经典包，包含80000 token使用权限, 以及高级会员特殊权限，有效期为365天, 产品价格为340元
•超级面试AI pro会员超值高级面试礼包，包含240000 token使用权限, 以及高级会员特殊权限，有效期为365天, 产品价格为880元
3.  会员(非高级会员)权益：在会员周期内，在使用我们的产品服务时享受实时问答生成以及客服在线支持权益。
4.  高级会员权益: 在会员周期内，在使用我们的产品服务时享受实时问答生成以及客服在线支持权益，此外还可享受联网搜索权益，从而使得在线生成信息更具有时效性等优点。
5. 付款：用户确认购买并付款后便可根据购买项目享受对应权益。
（三）使用规则
1您确认：您是具备完全民事权利能力和完全民事行为能力的自然人、法人或非法人组织，有能力对您使用付费会员服务的一切行为独立承担责任。若您不具备前述主体资格，橘洲科技在依据法律规定或本协议约定要求您承担责任时，有权向您的监护人或其他责任方追偿。
2您应妥善保存有关账号、密码，并对该账号进行的所有活动和行为负责，禁止赠与、借用、租用、转让或售卖该账号。您应自行负责妥善保管、使用、维护您在超级面试AI申请取得的账号、账号信息及账号密码。非橘洲科技原因致使您账号密码泄漏以及因您保管、使用、维护不当造成损失的，橘洲科技无须承担与此有关的任何责任。
3如您实施了下列行为之一，橘洲科技有权在不通知您的情况下，终止提供付费会员服务，并有权限制、冻结或终止与该服务相关联的超级面试AI账号使用。橘洲科技无须给予任何补偿和退费，由此产生的责任由您自行独立承担。因此给橘洲科技或第三方造成损失的，您应负责全额赔偿：
（1）以营利、经营等非个人使用的目的为自己或他人开通本服务；
（2）通过任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件等任何程序、软件方式为自己或他人开通本服务；
（3）通过任何不正当手段或以违反诚实信用原则的方式为自己或他人开通本服务；
（4）通过非超级面试AI指定的方式为自己或他人开通本服务；
（5）通过侵犯超级面试AI或他人合法权益的方式为自己或他人开通本服务；
（6）通过其他违反相关法律、行政法规、国家政策等的方式为自己或他人开通本服务。
4.双方同意，如发生下列任何一种情形，橘洲科技有权根据实际情况，在不通知您的情况下中断或终止向您提供的一项或多项或全部服务，由此产生的损失由您承担，橘洲科技无需给与任何补偿和退费。若因此给橘洲科技或第三方造成损失的，您应负责全额赔偿：
（1）根据法律法规政策规定您应提交真实信息，而您提供的个人资料不真实或与注册时信息不一致又未能提供合理证明；
（2）您违反相关法律法规政策或本协议、相关规则制度的约定；
（3）您的行为干扰了超级面试AI任何部分或功能的正常运行；
（4）您未经橘洲科技允许，利用超级面试AI开展未经橘洲科技同意的行为，包括但不限于对通过超级面试AI获得的信息进行商业化活动，如附加广告、商业内容或链接等；
（5）按照法律规定或主管部门的要求；
（6）您利用超级面试AI进行任何违法行为的；
（7）出于安全的原因或其他必要的情形。
 
五、未成年人保护
5.1 橘洲科技非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，应在监护人监护、指导下阅读本协议和使用付费会员服务，并且已经获得监护人之同意。
5.2 若监护人同意未成年人使用付费会员服务，必须以监护人名义申请消费，未成年人使用付费会员服务，以及行使和履行本协议项下的权利和义务即视为已获得了监护人的认可。
5.3 橘洲科技提醒未成年人用户在使用本服务时，要善于网上学习，认清网络世界与现实世界的区别，避免沉迷于网络，影响日常的学习生活。未成年人用户在使用付费会员服务时应注意以下事项，提高安全意识，加强自我保护：，
（1）填写个人资料时，加强个人保护意识，以免不良分子对个人生活造成骚扰；
（2）在监护人或老师的指导下，学习正确使用网络；
（3）避免陌生网友随意会面或参与联谊活动，以免不法分子有机可乘，危及自身安全。
5.4 监护人、学校均应对未成年人使用付费会员服务时多做引导，指导未成年人上网应该注意的安全问题，防患于未然。
 
六、【违约责任】
6.1 如果橘洲科技发现或收到他人举报您有违反本协议任何行为的，橘洲科技有权依法进行独立判断并采取技术手段予以删除、屏蔽或断开相关的信息链接。同时，橘洲科技有权视您的行为性质，对您采取包括但不限于暂停或终止部分或全部本服务、追究您法律责任等措施，橘洲科技也无需向您退还任何费用，而由此给您带来的损失（包括但不限于相关数据清空、未使用的服务费用作为违约金而归超级面试AI所有等），由您自行承担，造成超级面试AI损失的，您也应予以赔偿。
6.2 【对第三方损害的处理】
 您有违反本协议任何行为，导致任何第三方损害的，您应当独立承担责任；橘洲科技因此遭受损失的，您也应当一并赔偿。
6.3【赔偿责任】
6.3.1如您的违约行为使橘洲科技及其关联公司遭受损失，包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失，您应当赔偿橘洲科技及其关联公司的上述全部损失。
6.3.2如果因为您的违约行为导致任何第三方损害的，您应当独立承担责任。如因任何原因导致橘洲科技先行向第三方赔偿，您应该在收到橘洲科技的通知后立即将赔偿金支付给橘洲科技。
 
七、【责任限制】
7.1如果您购买/接受橘洲科技以外的第三方商户提供的商品或服务，如因此发生纠纷的，您应向销售/提供该商品或服务的第三方商户主张权利，与橘洲科技无关。
7.2您须对您自身及您所邀请的第三方在参加橘洲科技组织的活动或使用由橘洲科技提供的各项优惠及增值服务时的实施的一切行为承担全部法律责任。
7.3橘洲科技不对您因第三方的行为或不作为造成的损失、不可抗力原因造成的损失承担任何责任，包括但不限于支付服务、网络接入服务、电信部门的通讯线路故障、通讯技术问题、网络、电脑故障、系统不稳定性、任意第三方的侵权行为等。
7.4您理解并同意，在使用付费会员服务的过程中，可能会遇到不可抗力等风险因素，使该服务发生中断。如出现上述情况，橘洲科技承诺将尽快与相关单位配合进行修复，但不承担由此对您造成的任何损失且不退还付费会员费用。
7.5 您知悉并同意，用户在超级面试AI上上传、发布或传输的内容，为用户的个人行为，且橘洲科技有权进行审查及删除，但橘洲科技不对该等信息和内容的准确性、真实性、可用性、安全性、完整性和正当性承担责任，您在此同意豁免橘洲科技的相关责任。
7.6 在法律允许的范围内，橘洲科技对以下情形导致的服务中断或账号、账号内财产等丢失不承担责任，包括但不限于：
（1）受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；
（2）电信和网通部门的通讯线路故障、网络或电脑故障、系统不稳定；
（3）您的操作不当行为；
（4）您通过非橘洲科技授权的方式开通/使用付费会员服务；
（5）其他非橘洲科技过错，且无法控制或无法合理预见的情形。
7.7 您如果接触到违法、违规、使人感到不适不快甚至产生恐惧、厌恶情绪的内容，请您及时联系橘洲科技进行处理，非常感谢您的协助。
7.8 您在进行相关信息及内容的使用、观赏、分享、转载、转发、转述等行为（“使用行为”）时，应进行独立判断并合法使用，因您的使用行为造成任何自身损害及对第三方的损害，橘洲科技不承担任何责任。
7.9 橘洲科技依据本协议约定获得处理违法违规内容的权利，该权利不构成橘洲科技的义务或承诺，橘洲科技不能保证及时发现违法行为或进行相应处理。在任何情况下，您不应轻信借款、索要密码或其他涉及财产的网络信息。涉及财产操作的，请一定先核实对方身份。
7.10 您理解并同意，橘洲科技向您提供的付费会员服务或付费会员服务包含的学习资源等内容，可能会根据法律法规或监管政策的变化、权利人或版权方的要求、执法部门或司法机关的要求、其他第三方的投诉等进行变更或中断、中止或终止，包括但不限于橘洲科技公司有权对付费会员服务涉及的学习资源等内容采取临时或永久下架、停止提供学习、停止提供下载或缓存、停止或限制向付费会员提供服务内容、限制提供服务的设备终端等措施。对于因为前述原因或其他原因造成您无法享有付费会员相关服务或内容的，无需事先取得您的同意，且橘洲科技无需承担任何责任且无需给予任何形式的补偿和退费。
 
八、【其他】
8.1 【通知】
8.1.1本协议项下橘洲科技对于用户所有的通知均可通过站内信、手机短信、网站公告、客户预留的电子邮箱或常规的信件传送等方式进行。你理解并同意，本协议项下通知于发送之日视为已送达用户。
8.1.2为便于您收取相关通知，您应确保预留的联系方式为正确及有效，并在变更后及时登录超级面试AI账户修改。如因您未提供正确的联系信息或未及时告知新的联系方式，导致无法及时收取通知，将由您自行承担由此产生的损失及法律后果。
8.1.3橘洲科技可通过上述方式之一或其中若干向您送达各类通知，而此类通知的内容或将对您产生重大有利或不利影响，请您务必确保联系方式为有效并请及时关注。
8.2 【协议签订地】
本协议签订地为中华人民共和国中华人民共和国北京市海淀区。
8.3 【适用法律】
本协议的成立、生效、履行、解释及纠纷解决，受中华人民共和国法律管辖并根据中国法律解释。
8.4 【争议解决】
若您和橘洲科技之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。
8.5 【条款标题】
本协议所有条款的标题仅为阅读方便，本身并无实际含义，不能作为本协议含义解释的依据。
8.6 【自动续费协议】
若您欲开通付费会员服务的自动续费功能，需额外阅读并同意《自动续费服务规范》。自动续费服务规范为本协议的补充协议，您对补充协议、具体业务规则的接受，即视为您对本协议全部的接受。
8.7【条款效力】
本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
8.8【协议修改】
橘洲科技有权对本协议（包括本协议组成部分）进行修改（修改包含变更、增加、减少相应的条款内容），一旦本协议发生修改，橘洲科技将在相关页面提示或公示，请您务必仔细阅读。如果您不同意本协议的修改，可以停止对本服务的使用。您选择继续使用本服务，你可以采用接受服务的形式进行下一步操作或继续使用本服务，以表明您已经接受本协议的全部修改。如您有任何问题，可以咨询橘洲科技客服。更新后的本协议自更新版本发布之日起生效。
`

export default agreementContent;