import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$bus = new Vue();

const SESSION_DURATION = 9 * 60 * 60 * 1000; // 9 hours

// Navigation guard for authentication
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('auth');
  const sessionExpiration = localStorage.getItem('sessionExpiration');
  const now = new Date().getTime();

  if (sessionExpiration && now > sessionExpiration) {
    // Session has expired
    localStorage.removeItem('auth');
    localStorage.removeItem('sessionExpiration');
    next('/login');
  } else if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next('/login');
  } else {
    next();
  }
});

// Session expiration check
const sessionExpiration = localStorage.getItem('sessionExpiration');
const now = new Date().getTime();
if (sessionExpiration && now > sessionExpiration) {
  localStorage.removeItem('auth');
  localStorage.removeItem('sessionExpiration');
  router.push('/login');
} else if (localStorage.getItem('auth')) {
  // Update session expiration time on page load if user is authenticated
  localStorage.setItem('sessionExpiration', now + SESSION_DURATION);
}

// Axios interceptor for adding username to headers
axios.interceptors.request.use(config => {
  const username = localStorage.getItem('username');
  if (username) {
    config.headers['X-Username'] = username;
    config.headers["token"] = localStorage.getItem("auth");
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// Inactivity Timer
let inactivityTime = function () {
  let time;
  const logout = () => {
    localStorage.removeItem('auth');
    localStorage.removeItem('sessionExpiration');
    router.push('/login');
  };

  const resetTimer = () => {
    clearTimeout(time);
    time = setTimeout(logout, 120 * 60 * 1000); // 4 hours of inactivity
  };

  window.onload = resetTimer;
  document.onmousemove = resetTimer;
  document.onkeypress = resetTimer;
};

inactivityTime();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
