<template>
    <div id="app">

        <router-view class="router_view"/>
    </div>
</template>


<script>
import LogoutButton from './components/Logout.vue';  // Ensure the path is correct
import axios from 'axios';
export default {
    name: 'App',
    components: {
        LogoutButton
    },
    data() {
        return {
            isAuthenticated: !!localStorage.getItem('auth'), // Check if the user is authenticated
            username: localStorage.getItem('username') || '' ,// Store username locally if logged in
            logoutTimer: null,  // 添加定时器变量
        }
    },
    methods: {
        async handleLogout() {
        this.logout_loading = true;
        try {
            const response = await axios.post('/api/logout');
            console.log(response.data.message);
            localStorage.removeItem('auth');
            localStorage.removeItem('username');
            localStorage.removeItem('sessionExpiration');
            this.isAuthenticated = false;
            this.username = '';
            this.$router.push('/login');
        } catch (error) {
            console.error('Logout failed', error);
            // 可以在这里增加错误提示信息
        } finally {
            this.logout_loading = false;
        }
        this.clearLogoutTimer(); // 清除定时器
    },
    resetLogoutTimer() {
        clearTimeout(this.logoutTimer);
        this.logoutTimer = setTimeout(() => {
            this.handleLogout();
        }, 18000000); // 5 hours in milliseconds
    },
    clearLogoutTimer() {
        clearTimeout(this.logoutTimer);
        }
    },
    mounted() {
        document.addEventListener('mousemove', this.resetLogoutTimer);
        document.addEventListener('keydown', this.resetLogoutTimer);
        this.resetLogoutTimer();
    },
    beforeDestroy() {
        document.removeEventListener('mousemove', this.resetLogoutTimer);
        document.removeEventListener('keydown', this.resetLogoutTimer);
        this.clearLogoutTimer();
    },
    watch: {
        '$route'() {
            // Update authentication status and username on route change
            this.isAuthenticated = !!localStorage.getItem('auth');
            this.username = localStorage.getItem('username') || '';
        }
    },
    created() {
        // Optionally set the initial auth state and username when the app is loaded
        this.isAuthenticated = !!localStorage.getItem('auth');
        this.username = localStorage.getItem('username') || '';
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}

.router_view {
    margin-top: 10px;
}

.el-menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.menu-left, .menu-right {
    display: flex;
    align-items: center;
}

.menu-right {
    justify-content: flex-end;
}

.logout-button {
    margin-right: 20px; /* Optional: Adjust spacing */
}
</style>
