var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page1"},[_c('div',{staticClass:"wrap"},[_c('div',{staticClass:"txt wow fadeInUp",attrs:{"data-wow-delay":"0.3s","data-wow-duration":"1s"}},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"hmore"},[_c('a',{on:{"click":function($event){return _vm.$router.push({ name: 'HomeView' })}}},[_vm._v("立即免费试用 "),_c('img',{attrs:{"src":"images/ico2.png","alt":""}})])]),_vm._m(2)])])]),_vm._m(3),_c('div',{staticClass:"page3"},[_c('div',{staticClass:"wrap"},[_c('div',{staticClass:"tit wow fadeInUp",attrs:{"data-wow-delay":"0.3s","data-wow-duration":"1s"}},[_vm._v(" 为什么选超级面试？ ")]),_vm._m(4),_c('div',{staticClass:"txt wow fadeInUp",attrs:{"data-wow-delay":"0.3s","data-wow-duration":"1s"}},[_vm._m(5),_c('div',{staticClass:"hmore"},[_c('a',{on:{"click":function($event){return _vm.$router.push({ name: 'HomeView' })}}},[_vm._v("立即免费试用 "),_c('img',{attrs:{"src":"images/ico2.png","alt":""}})])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('i',[_vm._v("超级面试AI")]),_c('br'),_vm._v("聪明求职者的面试助手")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',[_vm._v(" 超级面试AI是一个AI面试模拟和面试实时辅助的平台 "),_c('br'),_vm._v(" 融合 "),_c('span',[_c('i',[_vm._v("100")]),_vm._v("+")]),_vm._v(" 求职导师课程训练的大型语言模型，让最专业的帮手助力你的面试！ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":"images/pic1.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page2"},[_c('div',{staticClass:"wrap"},[_c('div',{staticClass:"tit wow fadeInUp",attrs:{"data-wow-delay":"0.3s","data-wow-duration":"1s"}},[_vm._v(" 核心优势 ")]),_c('ul',{staticClass:"wow fadeInUp",attrs:{"data-wow-delay":"0.3s","data-wow-duration":"1s"}},[_c('li',[_c('div',{staticClass:"ico"},[_c('img',{attrs:{"src":"images/hico1.png","alt":""}})]),_c('h3',[_c('i',[_vm._v("智能精准")])]),_c('p',[_vm._v(" 面试过程中精准抓取面试官和面试者声音，根据交流情景实时生成满分答案，智能联网精准搜索必备的专业知识，专业教练数据辅助组织语言，助你专业自然地表达信息。 ")])]),_c('li',[_c('div',{staticClass:"ico"},[_c('img',{attrs:{"src":"images/hico2.png","alt":""}})]),_c('h3',[_c('i',[_vm._v("实时敏捷")])]),_c('p',[_vm._v(" 秒级收声与语音识别，支持无犹豫专业问题秒答，支持自行控制咨询任意句段，灵活应对突发状况。 ")])]),_c('li',[_c('div',{staticClass:"ico"},[_c('img',{attrs:{"src":"images/hico3.png","alt":""}})]),_c('h3',[_c('i',[_vm._v("稳定安全")])]),_c('p',[_vm._v(" 支持腾讯会议，zoom，飞书，牛客，小鱼易连，钉钉等主流面试会议软件，工具为非侵入式且反侵入，全程保护敏感数据，使用结束清除数据。 ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"wow fadeInUp",attrs:{"data-wow-delay":"0.3s","data-wow-duration":"1s"}},[_c('li',[_c('div',{staticClass:"ico"},[_c('img',{attrs:{"src":"images/hico4.png","alt":""}})]),_c('h3',[_c('i',[_vm._v("更专业")])]),_c('p',[_vm._v(" 拒绝泛语言模型，经过大量求职导师专业训练，让他们的智慧陪伴你每一场面试！ ")])]),_c('li',[_c('div',{staticClass:"ico"},[_c('img',{attrs:{"src":"images/hico5.png","alt":""}})]),_c('h3',[_c('i',[_vm._v("更懂你")])]),_c('p',[_vm._v("上传简历根据你的经历设计独特面试回答，不做“普”同学！")])]),_c('li',[_c('div',{staticClass:"ico"},[_c('img',{attrs:{"src":"images/hico6.png","alt":""}})]),_c('h3',[_c('i',[_vm._v("更靠谱")])]),_c('p',[_vm._v("测试用户100+，助力真实面试70场，好评率100%！")])]),_c('li',[_c('div',{staticClass:"ico"},[_c('img',{attrs:{"src":"images/hico8.png","alt":""}})]),_c('h3',[_c('i',[_vm._v("更贴心")])]),_c('p',[_vm._v(" 免费试用，一杯奶茶解决面试难题，更有月卡全程陪跑，求职季无忧！ ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('span',[_c('i',[_vm._v("300")]),_vm._v(" 求职者正在用超级面试AI聪明的收割offer")])])
}]

export { render, staticRenderFns }