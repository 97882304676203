<template>
  <div class="layout" style="background: url(images/hbg1.jpg) no-repeat center/cover">
    <div class="header">
      <div class="logo">
        <router-link :to="{ name: 'UserPanel' }">
          <img src="images/logo.png" alt="" />
        </router-link>
      </div>
      <div class="gh">
        <a href="javascript:;"></a>
      </div>
      <div class="rts">
        <div class="naver">
          <ul>
            <li :class="{ on: $route.name == 'Home' }" @click="hiddleMenu">
              <router-link :to="{ name: 'Home' }">首页</router-link>
            </li>
            <li :class="{ on: $route.name == 'HomeView' }" @click="hiddleMenu">
              <router-link :to="{ name: 'HomeView' }">面试助手</router-link>
            </li>
            <li :class="{ on: $route.name == 'IntervieweeInfo' }" @click="hiddleMenu">
              <router-link :to="{ name: 'IntervieweeInfo' }">上传面试者信息</router-link>
            </li>
            <li :class="{ on: $route.name == 'JobPositionInfo' }" @click="hiddleMenu">
              <router-link :to="{ name: 'JobPositionInfo' }">上传岗位信息</router-link>
            </li>
            <li :class="{ on: $route.name == 'PaymentOptions' }" @click="hiddleMenu">
              <router-link :to="{ name: 'PaymentOptions' }">充值</router-link>
            </li>
            <li :class="{ on: $route.name == 'UserPanel' }" @click="hiddleMenu">
              <router-link :to="{ name: 'UserPanel' }">个人信息中心</router-link>
            </li>
          </ul>
        </div>
        <div class="btn">
          <a @click="handleLogout" v-if="isAuthenticated" style="cursor: pointer;">
            <img src="images/ico1.png" alt="" />退出登录
          </a>
          <a @click="login" v-else style="cursor: pointer;">
            <img src="images/ico1.png" alt="" />登录
          </a>
        </div>
      </div>
    </div>

    <router-view />

    <div class="footer">
      <div class="wrap">
        <div class="icon">
          <img class="ico" src="images/ico3.png" alt="" />
          <div class="weix">
            <img src="2_demo/wechat.jpg" alt="" />
          </div>
        </div>
        <a>© 2024 super intervie版权所有 京ICP备2024078401号</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Layout",
  data() {
    return {
      isAuthenticated: localStorage.getItem("auth"),
    };
  },
  watch: {
    $route() {
      // 当路由变化时，更新认证状态
      this.isAuthenticated = !!localStorage.getItem("auth");
    },
  },
  methods: {
    hiddleMenu() {
      if ($(".gh").css("display") !== "none") {
        $(".layout").toggleClass("selected");
        $(".naver ul").slideToggle(0);
      }
    },
    login() {
      this.$router.push("/login");
    },
    async handleLogout() {
      this.logout_loading = true;
      try {
        const response = await axios.post("/api/logout");
        localStorage.removeItem("auth");
        localStorage.removeItem("username");
        localStorage.removeItem("sessionExpiration");

        this.$router.push("/login");
      } catch (error) {
        console.error("Logout failed", error);
        // 可以在这里增加错误提示信息
      }
    },
    jump(name) {
      this.$router.push({
        name,
      });
    },
  },
  mounted() {
    if (!/msie [6|7|8|9]/i.test(navigator.userAgent)) {
      new WOW().init();
    }
    $(".gh").click(function () {
      $(".layout").toggleClass("selected");
      $(".naver ul").slideToggle();
    });
    if ($(window).scrollTop() > 0) {
      $(".layout").addClass("on");
    } else {
      $(".layout").removeClass("on");
    }

    $(window).scroll(function () {
      if ($(window).scrollTop() > 0) {
        $(".layout").addClass("on");
      } else {
        $(".layout").removeClass("on");
      }
    });
  },
};
</script>
<style scoped lang="scss"></style>
