<template>
  <div
    class="mians"
    @keyup.enter="toggleCopilotState"
    @keyup.space="askCurrentText"
    @keyup.backspace="clearASRContent"
  >
    <div class="wrap wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
      <div class="flax">
        <div class="item">
          <h4><img src="images/mico3.png" alt="" /> 语音识别结果</h4>
          <div class="box"  ref="scrollBox">
            <ul>
              <li>
                <!-- <div class="ico">
                    <img src="images/mico5.png" alt="" />
                    <h5>面试官</h5>
                  </div> -->
                <div class="text">
                  <!-- <h6>14:45:32</h6> -->
                  <div
                    class="bk"
                    v-for="(text, index) in recognizedText"
                    :key="index"
                    v-if="text.trim()"
                  >
                    <div class="txt">
                      <p>
                        {{ text }}
                      </p>
                    </div>
                    <el-button
                      icon="el-icon-thumb"
                      @click="askGPT(index)"
                      style="margin-left: 1rem"
                    >
                      咨询
                    </el-button>
                  </div>
                  <!-- <h6>14:45:32</h6> -->
                  <!-- <div class="bk">
                      <div class="txt txt2">
                        <h3>
                          <img src="images/mico7.png" alt="" /><i>识别中</i>
                        </h3>
                      </div>
                    </div> -->
                </div>
              </li>
            </ul>
          </div>
          <div class="hmore">
            <a
              @click="
                () => {
                  !isContentEmpty && clearASRContent();
                }
              "
              ><img src="images/mico9.png" alt="" /><i>清除文本</i></a
            >
          </div>
        </div>
        <div class="item">
          <h4><img src="images/mico4.png" alt="" /> 超级面试AI回答</h4>
          <div class="box box31">
            <p class="result">
              {{ ai_result }}
            </p>
            <!-- <ul>
                <li>
            
                  <div class="text">
                    <div class="bk">
                      <div class="txt">
                        <p >
                          {{ ai_result }}
                        </p>
                      </div>
                    </div>
                 
                  </div>
                </li>
              </ul> -->
          </div>
          <div class="hmore">
            <a @click="askLatestText"
              ><img src="images/mico10.png" alt="" /><i
                >空格键快捷咨询最新对话</i
              ></a
            >
          </div>
        </div>
      </div>
      <!-- <div class="info-less">
      </div> -->

      <div class="hmore hmore2">
        <a
          @click="startCopilot"
          v-if="state === 'end'"
          :loading="copilot_starting"
          >开始智能识别</a
        >
        <a
          @click="stopCopilot"
          v-if="state === 'ing'"
          :loading="copilot_stopping"
          >停止智能识别
          <MyTimer ref="MyTimer" class="timer" />
        </a>
        <!-- <el-result
            icon="warning"
            title="您的Token余额不足，请充值后再使用。"
            v-if="showBalanceWarning"
          ></el-result> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import io from "socket.io-client";
import LoadingIcon from "@/components/LoadingIcon.vue";
import MyTimer from "@/components/MyTimer.vue";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";

export default {
  name: "mians",
  components: { LoadingIcon, MyTimer },

  data() {
    return {
      username: localStorage.getItem("username") || "未登录",
      currentText: "",
      state: "end", // end\ing
      ai_result: null,
      recognizedText: [],
      lastRecognizedTime: 0,
      lastDisplayedLength: 0,
      ai_result: "",
      incompleteText: "",
      copilot_starting: false, // 显示loading
      copilot_stopping: false,
      show_ai_thinking_effect: false,
      popStyle: {},
      recognition: null,
      remainToken: 100000,
      language: "zh-CN", // 默认语言设置
      isGenerating: false,
      queuedText: "",
      lastClickedIndex: null,
      showBalanceWarning: false, 
    };
  },
  computed: {
    isContentEmpty() {
      return !this.currentText.trim();
    },
    isGetGPTAnswerAvailable() {
      // return !!this.currentText.trim();
      return this.recognizedText.length > 0;
    },
    isDevMode() {
      return process.env.NODE_ENV === "development";
    },
  },
  async mounted() {
    console.log("mounted");
    if (this.isDevMode) {
      // this.currentText = demo_text
    }

    // 连接到 Socket.IO 服务器
    this.socket = io("https://super-interview.com", {
      path: "/socket.io",
      transports: ["websocket", "polling"],
    });
    // this.socket = io('https://super-interview.com');
    // this.socket = io('/');
    // this.socket.on('recognition_result', (data) => {
    //     this.currentText += data.text + '\n';
    // });
    this.socket.on("recognition_result", (data) => {
      this.currentText += data.text + "\n";
      this.handleRecognitionResult(data.text);
    });
    this.socket.on("text_cleared", (data) => {
      this.currentText = data.text;

      this.recognizedText = [];
    });
    this.socket.on("recognition_stopped", (data) => {
      console.log(data.message);
    });
    this.socket.on("response", (data) => {
      console.log("Received response from server:", data);
      if (data.end) {
        // End of generation signal
        this.isGenerating = false;

        // if (this.queuedText) {
        //     const queuedContent = this.queuedText;
        //     this.queuedText = '';
        //     this.askCurrentText(queuedContent); // Process the queued text
        // }
      } else {
        // Update the displayed result with the new text
        console.log("Checking last clicked index:", this.lastClickedIndex);
        if (this.lastClickedIndex !== null) {
          console.log("Updating result for index:", this.lastClickedIndex);
          this.ai_result = data.data; // Update the result
        } else {
          console.log("Ignoring response for unclicked index");
        }
      }
    });

    // 添加键盘事件监听器
    document.addEventListener("keydown", this.handleKeydown);

    // Fetch remaining tokens
    this.fetchRemainingTokens();
  },
  beforeDestroy() {
    if (this.state === 'ing') {
    this.stopCopilot();
   }
    // 在组件销毁之前断开 Socket.IO 连接
    if (this.socket) {
      this.socket.disconnect();
    }
    // 移除键盘事件监听器
    document.removeEventListener("keydown", this.handleKeydown);
  },
  methods: {
    getLastCompleteSentence(text) {
      const sentences = text.match(/[^\.!\?]+[\.!\?]+/g) || [];
      return sentences.pop() || ""; // returns the last complete sentence or an empty string if none
    },
    handleKeydown(event) {
      if (event.key === "Enter") {
        this.toggleCopilotState();
      } else if (event.key === " ") {
        event.preventDefault(); // 阻止默认的空格滚动行为
        this.askCurrentText();
      } else if (event.key === "Backspace") {
        this.clearASRContent();
      }
    },
    toggleCopilotState() {
      if (this.state === "end") {
        this.startCopilot();
      } else if (this.state === "ing") {
        this.stopCopilot();
      }
    },
    async fetchRemainingTokens() {
    try {
        const username = localStorage.getItem("username");
        const response = await axios.get(`/api/user/${username}`);
        this.remainNormalToken = response.data.remainNormalToken;
        this.remainAdvanceToken = response.data.remainAdvanceToken;

        // Show a warning if both token balances are low or zero
        if (this.remainNormalToken <= 0 && this.remainAdvanceToken <= 0) {
          this.showBalanceWarning = true;
        } else {
          this.showBalanceWarning = false;
        }
      } catch (error) {
        console.error("Failed to fetch remaining tokens:", error);
      }
    },
    async askLatestText() {
      if (!this.isGetGPTAnswerAvailable) {
        return;
      }
      const latestIndex = this.recognizedText.length - 1;
      if (latestIndex >= 0) {
        this.askGPT(latestIndex);
      }
    },
    askCurrentText() {
      this.askLatestText();
    },
    // async askCurrentText() {
    //     await this.fetchRemainingTokens();
    //     if (this.remainToken < 0) {
    //         this.$message.error('您的Token余额不足，请充值后再使用。');
    //         return;
    //     }

    //     // const content = this.currentText;
    //     const content = this.recognizedText.length > 0 ? this.recognizedText[this.recognizedText.length - 1] : '';
    //     console.log('Current Text:', content);
    //     if (!content) {
    //         return; // 如果内容为空，则不进行处理
    //     }

    //     if (this.isGenerating) {
    //         this.queuedText = content; // Queue the new text
    //         return;
    //     }

    //     this.isGenerating = true;
    //     this.ai_result = '';
    //     this.show_ai_thinking_effect = true;
    //     const username = localStorage.getItem('username'); // Retrieve username from localStorage
    //     const data = { username: username, question: content };
    //     this.socket.emit('send_prompt', data);

    //     // if (!content) {
    //     //     return; // 如果内容为空，则不进行处理
    //     // }
    //     // this.ai_result = '';
    //     // this.show_ai_thinking_effect = true;

    //     // try {
    //     //     const response = await axios.post('http://localhost:5000/api/ask_gpt', {
    //     //         question: content,
    //     //         username: this.username  // Ensure username is sent
    //     //     });

    //     //     this.show_ai_thinking_effect = false;
    //     //     this.ai_result = response.data.answer;
    //     // } catch (e) {
    //     //     this.show_ai_thinking_effect = false;
    //     //     this.ai_result = '' + e;
    //     // }
    // },
    clearASRContent() {
      this.currentText = ""; // Clear the accumulated text in the frontend
      this.socket.emit("clear_text", {});
    },
    // async startCopilot() {
    //     this.copilot_starting = true;
    //     try {
    //         // Request microphone permission
    //         const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    //         this.startRecognition();
    //         this.copilot_starting = false;
    //         this.state = 'ing';
    //         this.$refs.MyTimer.start();
    //         console.log('recognition started');
    //     } catch (e) {
    //         console.error('Error accessing microphone:', e);
    //         alert('麦克风访问被拒绝。请启用麦克风权限。');
    //         this.copilot_starting = false;
    //     }
    // },
    async startCopilot() {
      this.copilot_starting = true;
      try {
        // const tokenResponse = await axios.get(`http://localhost:5000/api/azure_token_and_language/${this.username}`);
        // const { api_key, region, language } = tokenResponse.data;
        const api_key = "6fbbb9e70762420a905425374f6ce5fa";
        const region = "eastus";
        const language = "zh-CN";
        this.language = language;
        this.startRecognition(api_key, region, language);
        this.copilot_starting = false;
        this.state = "ing";
        this.$refs.MyTimer.start();
        console.log("recognition started");
      } catch (e) {
        this.currentText = "" + e;
        this.copilot_starting = false;
      }
    },
    async stopCopilot() {
    this.copilot_stopping = true;
    try {
      const usedTime = this.$refs.MyTimer.stop(); // Stop the timer and get the used time
      this.state = "end";
      this.copilot_stopping = false;
      const usedTimeInMinutes = Math.floor(usedTime / 60); 
      // Send the used time to the backend
      const username = localStorage.getItem("username");
      await axios.post('/api/update_time', {
        username: username,
        used_time: usedTimeInMinutes // Convert seconds to minutes if needed
      });

      console.log("recognition stopped and time sent to backend");
    } catch (e) {
      this.currentText = "" + e;
      this.copilot_stopping = false;
    }
  },
    startRecognition(api_key, region, language) {
      const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
        api_key,
        region
      );
      speechConfig.speechRecognitionLanguage = language;
      const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
      this.recognizer = new SpeechSDK.SpeechRecognizer(
        speechConfig,
        audioConfig
      );

      this.recognizer.recognized = (s, e) => {
        if (e.result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
          this.currentText += e.result.text + " ";
          this.handleRecognitionResult(e.result.text);
        }
      };

      this.recognizer.startContinuousRecognitionAsync(
        () => {
          this.copilot_starting = false;
          this.state = "ing";
          this.$refs.MyTimer.start();
        },
        (err) => {
          this.copilot_starting = false;
          this.currentText = "Start Failed:" + err;
        }
      );
    },
    stopRecognition() {
      if (this.recognizer) {
        this.recognizer.stopContinuousRecognitionAsync(() => {
          this.state = "end";
          this.$refs.MyTimer.stop();
        });
      }
    },
    scrollToBottom() {
    this.$nextTick(() => {
      const scrollBox = this.$refs.scrollBox;
      scrollBox.scrollTop = scrollBox.scrollHeight;
    });
  },
    handleRecognitionResult(text) {
      const currentTime = new Date().getTime();
      const timeGap = currentTime - this.lastRecognizedTime;
      const threshold = 1000;

      if (
        timeGap > threshold &&
        this.recognizedText.length > 0 &&
        text.trim()
      ) {
        this.recognizedText.push("\n");
      }

      if (text.trim()) {
        this.recognizedText.push(`Speaker: ${text}`);
        this.scrollToBottom(); 
        // if (!this.isGenerating) {
        //     this.askCurrentText(); // Trigger askCurrentText immediately if not generating
        // } else {
        //     this.queuedText = text; // Queue the new text if generating
        // }
      }
      this.lastRecognizedTime = currentTime;
    },
    async askGPT(index) {
      await this.fetchRemainingTokens();
        if (this.remainNormalToken <= 0 && this.remainAdvanceToken <= 0) {
          this.$message.error("您的Token余额不足，请充值后再使用。");
          return;
        }

        // Proceed with the existing logic for asking GPT
        const content = this.recognizedText[index];
        if (!content) {
          console.log("No content found for index:", index);
          return;
        }

      // If there is an ongoing generation, stop it
      // if (this.isGenerating) {
      //     console.log('Stopping current generation for username:', this.username);
      console.log("send stop generation singal");
      this.socket.emit("stop_generation", { username: this.username }); // Emit an event to stop current generation
      //     this.isGenerating = false; // Ensure the flag is reset
      // }

      this.stopCurrentGeneration();

      // Set lastClickedIndex to the current index
      this.lastClickedIndex = index;
      console.log("Last clicked index set to:", this.lastClickedIndex);

      console.log(
        "Clearing previous results and starting new generation for content:",
        content
      );
      this.ai_result = "";
      this.isGenerating = true;

      this.show_ai_thinking_effect = true;
      const username = localStorage.getItem("username");
      const data = { username: username, question: content };
      this.socket.emit("send_prompt", data);
    },
    stopCurrentGeneration() {
      if (this.isGenerating) {
        console.log("Stopping current generation for username:", this.username);
        this.socket.emit("stop_generation", { username: this.username });
        this.isGenerating = false; // Ensure the flag is reset
      }
    },
  },
};
</script>
<style scoped>


.hmore2,
.hmore a {
  cursor: pointer;
}
.timer {
  position: absolute;
  padding-left: 18%;
  color: rgb(251, 223, 62);
}
.bk {
  display: flex !important;
  width: 100%;
}
.bk text {
  flex: 1;
  overflow: hidden;
}
.bk .el-button {
  align-items: center;
  flex-shrink: 0;
  align-self: center;
}
.box31 .result {
  display: inline-block;
  padding: 0.9375rem 1.8229166666666667rem;
  border-radius: 0.5208333333333334rem;
  margin-bottom: 0.5208333333333334rem;
  background: #282828;

  font-size: 0.9375rem;
  line-height: 1.6;
  color: #ffffff;
}
.hmore.hmore2 {
  position: relative;
}
</style>
<style>
.flax {
  display: flex;
  justify-content: space-between;
  height: 80vh; /* 设置问答框占据屏幕的80%高度 */
  /* width: 100vw; */
  /* margin-top: 10px; */
  margin-left: 10px;
}

.item {
  flex: 1;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
}

.item .box {
  flex: 1;
  padding: 10px;
  background: #f8f8f8;
  border-radius: 10px;
  overflow-y: auto; /* 允许内容超出时滚动 */
}

.item .hmore {
  text-align: right;
  margin-top: 10px;
}

.hmore2 {
  position: relative;
  margin-top: 20px;
  text-align: center;
}

.el-result {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  z-index: 1000;
  padding: 0 !important;
}

.el-result .el-result__title p {
  color: #fce84c;
}
</style>
