<template>
    <div class="wechat-pay">
        <h1 class="highlight">AI协助面试的优势</h1>
        <div class="info">
            <p>我们的AI系统能够帮助您快速、高效地完成面试流程，确保每一步都万无一失。</p>
            <p><span class="highlight-text">无论是初次面试还是复试</span>，AI助手都能为您提供实时建议和优化策略，让您的表现更加出色。</p>
            <p>通过智能分析和个性化指导，我们的AI面试助手将成为您求职路上的得力助手，<span class="highlight-text">助您轻松拿下心仪职位</span>。</p>
            <p><span class="highlight-text">立即体验</span>，享受前所未有的面试准备新方式，迈向成功的第一步！</p>
        </div>
        <div class="help">
            <h2 class="highlight">使用指南</h2>
            <p>在使用AI面试助手时，您可以通过以下键盘快捷键进行操作：</p>
            <ul>
                <li><strong>Enter键</strong>：启动/停止协助模式</li>
                <li><strong>空格键</strong>：快速咨询AI助手最新的对话内容</li>
                <li><strong>Backspace键</strong>：清除文本</li>
            </ul>
        </div>
        <div class="customer-service">
            <h2 class="highlight">联系客服</h2>
            <img :src="customerServiceQR" alt="客服二维码" class="animated-image" />
            <p>我们的客服为您提供支持，解决您在使用过程中的任何问题，<span class="highlight-text">12小时内回复</span>。</p>
            <p>扫描二维码, 我们将竭诚为您服务，确保您的使用体验顺畅无忧。</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            customerServiceQR: 'demo.png' // 替换为实际的客服二维码路径
        };
    }
}
</script>

<style scoped>
.wechat-pay {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5; /* Matches the interviewee information page background */
    color: #333; /* Matches the interviewee information page text color */
    padding: 20px;
    border: 1px solid #ddd; /* Matches the interviewee information page border */
    font-family: Arial, sans-serif;
}
.wechat-pay h1, .wechat-pay h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}
.wechat-pay .highlight {
    color: #409EFF;
    font-size: 28px;
    font-weight: bold;
    animation: highlight-fade 2s infinite; /* Add animation to highlight */
}
@keyframes highlight-fade {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.5; }
}
.wechat-pay img {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    transition: transform 0.3s; /* Add smooth transition */
}
.wechat-pay img:hover {
    transform: scale(1.1); /* Scale up image on hover */
}
.info, .help, .customer-service {
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #ddd;
    text-align: left;
    max-width: 600px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
}
.info p, .help p, .help ul, .customer-service p {
    margin-bottom: 10px;
    line-height: 1.6;
}
.help ul {
    padding-left: 20px;
}
.help li {
    margin-bottom: 5px;
}
.highlight-text {
    color: #409EFF;
    font-weight: bold;
    background-color: #e0f7ff;
    padding: 2px 4px;
    border-radius: 3px;
    transition: background-color 0.3s; /* Add smooth transition */
}
.highlight-text:hover {
    background-color: #b3e5fc; /* Change background color on hover */
}
.customer-service {
    text-align: center;
}
.customer-service h2 {
    color: #409EFF;
    margin-bottom: 10px;
}
.animated-image {
    animation: pulse 2s infinite; /* Add pulse animation */
}
@keyframes pulse {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.05); }
}
</style>
