<template>
    <div class="container">
        <div class="background"></div>
        <div class="content">
            <div class="ad-section">
                <h1>AI面试助手</h1>
                <p>您的专属AI面试助手，助您在面试中脱颖而出！</p>
                <p>智能分析，精准建议，提升您的面试成功率。</p>
                <p>立即注册，体验前所未有的面试准备方式！</p>
            </div>
            <div class="register-box">
                <h2>注册</h2>
                <el-form ref="registerForm" :model="registerForm" :rules="rules" label-width="80px">
                    <el-form-item label="用户名" prop="username">
                        <el-input v-model="registerForm.username" placeholder="请输入用户名"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                        <div class="password-container">
                            <el-input
                                :type="showPassword ? 'text' : 'password'"
                                v-model="registerForm.password"
                                placeholder="请输入密码"
                            ></el-input>
                            <button type="button" @click="togglePasswordVisibility" class="eye-button">
                                <i :class="showPassword ? 'el-icon-view-off' : 'el-icon-view'"></i>
                            </button>
                        </div>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="confirm">
                        <el-input :type="showPassword ? 'text' : 'password'" v-model="registerForm.confirm" placeholder="请确认密码"></el-input>
                    </el-form-item>
                    <el-form-item label="推荐码" prop="referralCode">
                        <el-input v-model="registerForm.referralCode" placeholder="请输入推荐码"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <div class="agreement-section">
                            <el-checkbox v-model="agreementAccepted">阅读并同意</el-checkbox>
                            <a href="#" @click.prevent="openUserAgreementModal">《用户协议》</a>
                            和
                            <a href="#" @click.prevent="openPrivacyPolicyModal">《隐私协议》</a>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="handleRegister">注册</el-button>
                    </el-form-item>
                </el-form>
                <p class="login-link">
                    <router-link to="/login">已有账户？登录</router-link>
                </p>
            </div>
        </div>
        <!-- User Agreement Modal -->
        <el-dialog :visible.sync="showUserAgreementModal" width="80%">
            <span slot="title">用户协议</span>
            <div class="agreement-content">
                <pre>{{ userAgreementContent }}</pre>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showUserAgreementModal = false">关闭</el-button>
            </span>
        </el-dialog>

        <!-- Privacy Policy Modal -->
        <el-dialog :visible.sync="showPrivacyPolicyModal" width="80%">
            <span slot="title">隐私协议</span>
            <div class="agreement-content">
                <pre>{{ privacyPolicyContent }}</pre>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showPrivacyPolicyModal = false">关闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios';
import userAgreementContent from '@/assets/userAgreementContent.js';
import privacyPolicyContent from '@/assets/privacyPolicyContent.js';

export default {
    name: 'Register',
    data() {
        return {
            registerForm: {
                username: '',
                password: '',
                confirm: ''
            },
            showPassword: false,
            agreementAccepted: false,
            showUserAgreementModal: false,
            showPrivacyPolicyModal: false,
            userAgreementContent: userAgreementContent, // Imported user agreement content
            privacyPolicyContent: privacyPolicyContent, // Imported privacy policy content
            rules: {
                username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
                password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
                confirm: [{ required: true, message: '请确认密码', trigger: 'blur' }]
            }
        };
    },
    methods: {
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
        handleRegister() {
            if (!this.agreementAccepted) {
                this.$message.error('请输入正确的用户名，密码，并阅读并同意《用户协议》和《隐私协议》');
                return;
            }

            this.$refs.registerForm.validate(valid => {
                if (valid) {
                    if (this.registerForm.password.length < 8 || this.registerForm.password.length > 16) {
                        this.$message.error('密码应为8到16个字符之间');
                        return;
                    }
                    if (this.registerForm.password !== this.registerForm.confirm) {
                        this.$message.error('密码不一致！');
                        return;
                    }

                    // 尝试连接后端
                    axios.post('/api/register', this.registerForm)
                        .then(response => {
                            this.$message.success('注册成功！');
                            this.$router.push('/login');
                        })
                        .catch(error => {
                            console.log('注册请求出错:', error);
                            if (error.response && error.response.data) {
                                console.log('响应数据:', error.response.data);
                                // 检查错误是否由于用户已存在
                                if (error.response.status === 400 && error.response.data.message === "用户已存在") {
                                    this.$message.error('用户名已被占用，请选择其他用户名。');
                                } else {
                                    this.$message.error('注册过程中出现问题，请稍后再试。');
                                }
                            }
                        });
                } else {
                    console.log('表单验证失败');
                    return false;
                }
            });
        },
        openUserAgreementModal() {
            this.showUserAgreementModal = true;
        },
        openPrivacyPolicyModal() {
            this.showPrivacyPolicyModal = true;
        },
    }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

body {
    font-family: 'Open Sans', sans-serif;
    background-color: #f8f8f8;
    overflow: hidden; /* 禁止滚动 */
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('demo_new.jpg') no-repeat center center fixed;
    background-size: cover;
    filter: blur(2px); /* 调整模糊效果 */
    z-index: -1;
}

.content {
    display: flex;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    max-width: 900px;
    width: 100%;
    position: relative;
    z-index: 1;
}

.ad-section {
    padding: 40px;
  background: #2c3e50;
  color: black;
  display: flex;
  flex-direction: column;
  background: -webkit-linear-gradient(left, #fdec54, #ffb64e);
  justify-content: center;
  flex: 1;
  font-weight: 600;
}

.ad-section h1 {
    margin-bottom: 20px;
    font-size: 24px;
}

.ad-section p {
    margin: 10px 0;
}

.register-box {
    padding: 40px;
    background-color: #262626;
    width: 400px;
}

.register-box h2{
    font-size: 2rem;
}
.register-box  ::v-deep .el-form-item__label {
    color: white;
}
.register-box *{
    color: white !important;
}

h2 {
    text-align: center;
    margin-bottom: 30px;
    color: #2c3e50;
}

.sub-text {
    text-align: center;
    margin-bottom: 20px;
    color: #999;
}

.password-container {
    display: flex;
    align-items: center;
}

.eye-button {
    background: none;
    border: none;
    margin-left: 10px;
    cursor: pointer;
}

.login-link {
    text-align: center;
    margin-top: 20px;
}

.agreement-section {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.agreement-section a {
    color: #409EFF;
    margin-left: 5px;
    cursor: pointer;
    text-decoration: none;
}

.agreement-section a:hover {
    text-decoration: underline;
}


.agreement-content {
    padding: 20px; /* Add padding inside the content */
    max-height: 70vh; /* Set a maximum height for the content */
    overflow-y: auto; /* Allow vertical scrolling if content overflows */
    white-space: pre-wrap; /* Handle white spaces and line breaks correctly */
    background-color: #fff; /* Ensure a white background for better readability */
    border-radius: 8px; /* Add some rounding to match the modal design */
}

</style>
