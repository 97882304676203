<template>
  <div>
    <div class="page1">
      <div class="wrap">
        <div
          class="txt wow fadeInUp"
          data-wow-delay="0.3s"
          data-wow-duration="1s"
        >
          <h2><i>超级面试AI</i><br />聪明求职者的面试助手</h2>
          <h5>
            超级面试AI是一个AI面试模拟和面试实时辅助的平台 <br />
            融合
            <span><i>100</i>+</span>
            求职导师课程训练的大型语言模型，让最专业的帮手助力你的面试！
          </h5>
          <div class="hmore">
            <a @click="$router.push({ name: 'HomeView' })">立即免费试用 <img src="images/ico2.png" alt=""/></a>
          </div>
          <div class="pic">
            <img src="images/pic1.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="page2">
      <div class="wrap">
        <div
          class="tit wow fadeInUp"
          data-wow-delay="0.3s"
          data-wow-duration="1s"
        >
          核心优势
        </div>
        <ul class="wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
          <li>
            <div class="ico">
              <img src="images/hico1.png" alt="" />
            </div>
            <h3><i>智能精准</i></h3>
            <p>
              面试过程中精准抓取面试官和面试者声音，根据交流情景实时生成满分答案，智能联网精准搜索必备的专业知识，专业教练数据辅助组织语言，助你专业自然地表达信息。
            </p>
          </li>
          <li>
            <div class="ico">
              <img src="images/hico2.png" alt="" />
            </div>
            <h3><i>实时敏捷</i></h3>
            <p>
              秒级收声与语音识别，支持无犹豫专业问题秒答，支持自行控制咨询任意句段，灵活应对突发状况。
            </p>
          </li>
          <li>
            <div class="ico">
              <img src="images/hico3.png" alt="" />
            </div>
            <h3><i>稳定安全</i></h3>
            <p>
              支持腾讯会议，zoom，飞书，牛客，小鱼易连，钉钉等主流面试会议软件，工具为非侵入式且反侵入，全程保护敏感数据，使用结束清除数据。
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div class="page3">
      <div class="wrap">
        <div
          class="tit wow fadeInUp"
          data-wow-delay="0.3s"
          data-wow-duration="1s"
        >
          为什么选超级面试？
        </div>
        <ul class="wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
          <li>
            <div class="ico">
              <img src="images/hico4.png" alt="" />
            </div>
            <h3><i>更专业</i></h3>
            <p>
              拒绝泛语言模型，经过大量求职导师专业训练，让他们的智慧陪伴你每一场面试！
            </p>
          </li>
          <li>
            <div class="ico">
              <img src="images/hico5.png" alt="" />
            </div>
            <h3><i>更懂你</i></h3>
            <p>上传简历根据你的经历设计独特面试回答，不做“普”同学！</p>
          </li>
          <li>
            <div class="ico">
              <img src="images/hico6.png" alt="" />
            </div>
            <h3><i>更靠谱</i></h3>
            <p>测试用户100+，助力真实面试70场，好评率100%！</p>
          </li>
          <li>
            <div class="ico">
              <img src="images/hico8.png" alt="" />
            </div>
            <h3><i>更贴心</i></h3>
            <p>
              免费试用，一杯奶茶解决面试难题，更有月卡全程陪跑，求职季无忧！
            </p>
          </li>
        </ul>
        <div
          class="txt wow fadeInUp"
          data-wow-delay="0.3s"
          data-wow-duration="1s"
        >
          <h4>
            <span><i>300</i> 求职者正在用超级面试AI聪明的收割offer</span>
          </h4>
          <div class="hmore">
            <a @click="$router.push({ name: 'HomeView' })"
              >立即免费试用 <img src="images/ico2.png" alt=""
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",

  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() {},
};
</script>
<style scoped>
.hmore a{
  cursor: pointer;
}
</style>
