<template>
    <div class="archive-container">
        <el-row>
            <el-col :span="24">
                <div class="header">
                    <h1>信息汇总</h1>
                    <div class="brand">信息统计</div>
                </div>
            </el-col>
        </el-row>
        <el-row class="main-content">
            <el-col :span="6">
                <div class="sidebar">
                    <el-button type="primary" class="sidebar-btn" @click="viewInterviewees">查看面试者信息</el-button>
                    <el-button type="primary" class="sidebar-btn" @click="viewJobPositions">岗位信息</el-button>
                </div>
            </el-col>
            <el-col :span="18" >
                <el-card v-for="item in items" :key="item._id" class="info-card" @click="editItem(item)">
                    <div class="card-header">
                        <el-button icon="el-icon-delete" @click.stop="confirmDelete(item._id)"></el-button>
                    </div>
                    <div class="card-content">
                        <h3>{{ item.name || item.companyName }}</h3>
                        <template v-if="viewMode === 'interviewees'">
                            <div v-if="item.gender"><strong>性别:</strong> {{ item.gender }}</div>
                            <div v-if="item.age"><strong>年龄:</strong> {{ item.age }}</div>
                            <div v-if="item.education"><strong>教育经历:</strong> {{ item.education }}</div>
                            <div v-if="item.skills"><strong>专业技能:</strong> {{ item.skills }}</div>
                            <div v-if="item.internship"><strong>实习经历:</strong> {{ item.internship }}</div>
                            <div v-if="item.additionalInfo"><strong>补充信息:</strong> {{ item.additionalInfo }}</div>
                        </template>
                        <template v-if="viewMode === 'jobpositions'">
                            <div><strong>公司名称:</strong> {{ item.companyName }}</div>
                            <div><strong>公司信息:</strong> {{ item.companyInfo }}</div>
                            <div><strong>岗位信息:</strong> {{ item.jobInfo }}</div>
                        </template>
                    </div>
                </el-card>
                <el-card class="add-card" @click="addItem">
                    <div class="add-icon">+</div>
                    <p>添加新信息</p>
                </el-card>
            </el-col>
        </el-row>
        <el-dialog :visible.sync="dialogVisible" title="确认删除">
            <span>确定要删除此信息吗？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="deleteItem">确定删除</el-button>
            </span>
        </el-dialog>
    </div>
</template>



<script>
import axios from 'axios';

export default {
    data() {
        return {
            dialogVisible: false,
            items: [],
            currentDeleteId: null,
            viewMode: 'interviewees'
        };
    },
    methods: {

        fetchItems() {
            const endpoint = this.viewMode === 'interviewees' ? '/api/interviewees' : '/api/jobpositions';
            axios.get(endpoint)
                .then(response => {
                    this.items = response.data;
                })
                .catch(error => {
                    console.error('获取信息失败', error);
                });
        },
        editItem(item) {
            const route = this.viewMode === 'interviewees' ? '/interviewee-info' : '/jobposition-info';
            this.$router.push({ path: route, query: { id: item._id } });
        },
        confirmDelete(id) {
            this.currentDeleteId = id;
            this.dialogVisible = true;
        },
        deleteItem() {
            const endpoint = this.viewMode === 'interviewees' ? `/api/interviewees/${this.currentDeleteId}` : `/api/jobpositions/${this.currentDeleteId}`;
            axios.delete(endpoint)
                .then(() => {
                    this.dialogVisible = false;
                    this.fetchItems();
                })
                .catch(error => {
                    console.error('删除信息失败', error);
                    this.$message.error('删除信息失败');
                });
        },
        addItem() {
            const route = this.viewMode === 'interviewees' ? '/interviewee-info' : '/jobposition-info';
            this.$router.push(route);
        },
        viewInterviewees() {
            this.viewMode = 'interviewees';
            this.fetchItems();
        },
        viewJobPositions() {
            this.viewMode = 'jobpositions';
            this.fetchItems();
        }
    },
    mounted() {
        this.fetchItems();
    }
};
</script>

<style scoped>
.archive-container {
    padding: 20px;
    background-color: #f9f9f9;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header h1 {
    font-size: 28px;
    margin: 0;
    color: #333;
}
.brand {
    font-size: 22px;
    color: #555;
}
.sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-around; /* Ensures even spacing and full fill */
    height: 100%; /* Full height to match the right content */
}
.sidebar-btn {
    margin-bottom: 10px;
    width: 100%;
    height: 48%; /* Makes button fill half of the sidebar height */
    text-align: center;
    border-radius: 8px;
    background-color: #409EFF;
    border: none;
    color: #fff;
}
.sidebar-btn:hover {
    background-color: #307DE0;
}
.main-content {
    margin-top: 20px;
}
.info-card, .add-card {
    margin-bottom: 20px;
    padding: 20px;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}
.info-card {
    background-color: #fff;
    transition: all 0.3s ease;
}
.info-card:hover {
    box-shadow: 0 6px 12px rgba(0,0,0,0.15);
    transform: translateY(-2px);
}
.card-header {
    display: flex;
    justify-content: flex-end;
}
.card-content h3 {
    margin: 0;
    font-size: 24px;
    color: #333;
}
.card-content p {
    margin: 5px 0;
    color: #666;
    line-height: 1.5;
}
.add-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fafafa;
    border: 2px dashed #ccc;
    text-align: center;
}
.add-icon {
    font-size: 48px;
    color: #bbb;
}
.dialog-footer {
    text-align: right;
}
</style>
