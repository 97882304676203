<template>
  <div class="chongzhi">
    <div class="hd wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
      <div class="wrap">
        <h2 class="mti"><i>专家面试会员</i></h2>
        <h5>适用于</h5>
        <ul>
          <li>
            <h4><i>01</i></h4>
            <p>
              对素质面、行为面、专业面、技术面、经历面、主管面在内的全部普通面试场景。
            </p>
          </li>
          <li>
            <h4><i>02</i></h4>
            <p>需要专业知识、行业资讯、常用术语、简单逻辑的高压面试场景。</p>
          </li>
          <li>
            <h4><i>03</i></h4>
            <p>
              需要快速临场反应、专属行业知识库、岗位面经数据库、知识细节挖掘、系统思考逻辑、最新真实资讯的深度面试场景。
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div class="list wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
      <div class="wrap">
        <h2 class="mti"><i>使用推荐码购买月度会员</i></h2>
        <ul>
          <li v-for="plan in plans" :key="plan.id">
            <a href="#">
              <div class="sup">
                <i>{{ plan.label }}</i>
              </div>
              <h4>
                <i>{{ plan.title }}</i>
              </h4>
              <h3>
                <i>￥{{ plan.price }}</i
                ><s>￥{{ plan.originalPrice }}</s>
              </h3>
              <div class="box">
                <h5>
                  <i>{{ plan.baseDuration }}</i> 基础时长
                </h5>
                <h6
                  v-if="
                    plan.bonusDuration !== undefined &&
                    plan.bonusDuration !== null
                  "
                  class="bonus-duration"
                >
                  {{ plan.bonusDuration }}
                </h6>
                <h6
                  v-else
                  style="height: 26px; visibility: hidden"
                  class="bonus-duration-empty"
                ></h6>
              </div>
              <h2>{{ plan.tag }}</h2>
              <div class="btn" @click="openPaymentModal(plan)">购买</div>
              <p>{{ plan.restriction }}</p>
              <p v-if="plan.paymentStatus">
                支付状态: {{ plan.paymentStatus }}
              </p>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="ft"
      style="background: url(images/bg2.png) no-repeat center top/cover"
    >
      <div
        class="wrap wow fadeInUp"
        data-wow-delay="0.3s"
        data-wow-duration="1s"
      >
        <div class="tit">使用教程</div>
        <h3><i>使用文档&常见问题</i></h3>
        <p>斩获简历面、专业面、HR面、学校面的各类面试，收获心仪OFFER！</p>
        <div class="hmore">
          <a
            href="https://mvdm5380b07.feishu.cn/docx/VabpdhB7noPw7FxnY0LcSTUbnyc?from=from_copylink"
            target="_blank"
            >使用文档&常见问题
          </a>
        </div>
        <div class="tit" style="margin-top: 50px">右下角扫描微信客服二维码</div>
        <div class="qr-code-container-new">
          <img src="/images/wechat.jpg" alt="WeChat QR Code" width="300" height="400"/>
        </div>
      </div>
    </div>
    <div v-if="showPaymentModal" class="payment-modal">
      <div class="modal-content">
        <h3 class="modal-title">支付渠道</h3>
        <hr class="divider" />
        <div class="payment-info">
          <p class="payment-label">支付金额:</p>
          <p class="payment-amount">￥{{ selectedPlan.price }}</p>
        </div>
            <div class="payment-info" v-if="selectedPlan.id === 3">
              <p class="payment-label">导师推荐码:</p>
              <p class="payment-amount">
                <el-input
                  v-model="mentorCode"
                  placeholder="请输入导师推荐码"
                ></el-input>
              </p>
            </div>
        <div class="payment-info">
          <p class="payment-label">支付平台:</p>
          <div class="payment-icons">
            <div
              class="payment-option"
              :class="{ selected: selectedPaymentMethod === 'alipay' }"
              @click="selectPaymentMethod('alipay')"
            >
              <img src="@/assets/alipay-icon.jpeg" alt="支付宝" />
            </div>
            <div
              Interview-ai123
              class="payment-option"
              :class="{ selected: selectedPaymentMethod === 'wechat' }"
              @click="selectPaymentMethod('wechat')"
            >
              <img src="@/assets/wechatpay-icon.jpeg" alt="微信" />
            </div>
          </div>
        </div>

        <div class="agreement-section">
          <input type="checkbox" v-model="agreementAccepted" />
          <span>阅读并同意</span>
          <a href="#" @click.prevent="openAgreementModal">《会员服务协议》</a>
        </div>

        <div class="modal-buttons">
          <button @click="processPayment">购买</button>
          <button @click="closePaymentModal">取消</button>
        </div>
      </div>
    </div>
    <div v-if="showQRCodeModal" class="payment-modal">
      <div class="modal-content">
        <h3 class="modal-title">微信支付二维码</h3>
        <hr class="divider" />
        <div class="qr-code-container">
          <img :src="qrCodeUrl" alt="WeChat Pay QR Code" />
        </div>
        <div class="modal-buttons">
          <button @click="verifyPayment">完成支付</button>
          <button @click="closeQRCodeModal">关闭</button>
        </div>
      </div>
    </div>
    <div v-if="showNotification" class="notification">
      {{ notificationMessage }}
    </div>
    <!-- Agreement Modal -->
    <div v-if="showAgreementModal" class="agreement-modal">
      <div class="agreement-content">
        <h3>会员服务协议</h3>
        <button class="close-btn" @click="closeAgreementModal">关闭</button>
        <div class="agreement-text">
          <pre>{{ agreementContent }}</pre>
          <!-- Display the agreement content -->
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import agreementContent from "@/assets/agreementContent.js";
console.log("Imported Agreement Content:", agreementContent);
export default {
  name: "PaymentPlans",
  data() {
    return {
      paymentPollingInterval: null, // 存储轮询定时器的引用
      plans: [
        {
          id: 1,
          title: "超值专家面试包",
          tag: "两杯奶茶，解决一次面试",
          label: "专业可靠",
          price: 65,
          originalPrice: 139,
          baseDuration: "30分钟",
          bonusDuration: "15分钟",
          restriction: "此套餐无限制购买",
          tokenAmount: 20000,
        },
        {
          id: 2,
          title: "教练力荐面试包",
          tag: "一顿火锅，稳过一家公司",
          price: 199,
          label: "教练推荐",
          originalPrice: 399,
          baseDuration: "2小时",
          bonusDuration: "45分钟",
          restriction: "此套餐无限制购买",
          tokenAmount: 100000,
        },
        {
          id: 3,
          title: "月卡会员求职包",
          tag: "一次购买，陪伴整个求职周期",
          price: 799,
          label: "导师严选",
          originalPrice: 1999,
          baseDuration: "10+小时",
          restriction: "此套餐有限制购买",
          tokenAmount: 500000,
        },
        {
          id: 4,
          title: "应急标准包",
          tag: "两餐食堂，应对基础面试",
          price: 35,

          label: "流畅清晰",

          originalPrice: 88,
          baseDuration: "30分钟",
          bonusDuration: "10分钟",
          restriction: "此套餐有限制购买",
          tokenAmount: 500000,
        },
        {
          id: 5,
          title: "内网信息包",
          tag: "一顿外卖，检索全网信息",
          price: 59,
          label: "严谨全面",

          originalPrice: 148,
          baseDuration: "60分钟",
          bonusDuration: "30分钟",
          restriction: "此套餐有限制购买",
          tokenAmount: 500000,
        },
      ],
      showPaymentModal: false,
      selectedPlan: null,
      selectedPaymentMethod: null,
      agreementAccepted: false,
      showAgreementModal: false,
      agreementContent: agreementContent, // To hold the content of the agreement
      // showNotification: false,
      notificationMessage: "",
      qrCodeUrl: null,
      showQRCodeModal: false,
      // 月度会员部分
      isMenberWay: false,
      couponCode: "",
      mentorCode: '',
    };
  },
  mounted() {
    console.log("Full URL:", window.location.href);
    const hashString = window.location.hash.slice(1); // 取得 hash 部分并去掉 #
    console.log("Hash String:", hashString);
    const hashParams = new URLSearchParams(hashString.split("?")[1]); // 取得 ? 后的部分进行解析
    console.log("HashParams Entries:");
    for (const [key, value] of hashParams.entries()) {
      console.log(`${key}: ${value}`);
    }
    const orderId = hashParams.get("order_id");
    const status = hashParams.get("status");

    console.log("Order ID:", orderId);
    console.log("Status:", status);
    if (orderId && status) {
      this.handlePaymentReturn(orderId, status);
    }
  },
  methods: {
    verifyPayment() {
      // Show a notification to the user
      this.showNotificationMessage("正在验证您的支付，请稍后", "info");

      // Query the payment status
      this.pollPaymentStatus(
        this.orderId,
        this.selectedPlan,
        this.selectedPaymentMethod
      );
    },
    openPaymentModal(plan) {
      this.selectedPlan = plan;
      this.showPaymentModal = true;
      this.selectedPaymentMethod = null;
      this.agreementAccepted = false; // Reset agreement acceptance
    },
    closePaymentModal() {
      this.showPaymentModal = false;
      this.selectedPlan = null;
      this.selectedPaymentMethod = null;
      this.agreementAccepted = false;
    },
    selectPaymentMethod(method) {
      this.selectedPaymentMethod = method;
    },
    processPayment() {
      console.log(123123);
      if (!this.agreementAccepted) {
        this.$message.error("请选择支付方式，且阅读并同意《会员服务协议》");
        return;
      }
      if (this.selectedPlan.id===3 && this.mentorCode==="") {
        this.$message.error("请输入导师推荐码");
        return;
      }


      if (this.selectedPaymentMethod === "alipay") {
        this.initiatePayment(this.selectedPlan, this.mentorCode,"/api/pay/create");
      } else if (this.selectedPaymentMethod === "wechat") {
        this.initiatePayment(this.selectedPlan, this.mentorCode, "/api/wechatpay_create");
      } else {
        this.$message.error("请选择支付方式，且阅读并同意《会员服务协议》");
      }
    },
    showNotificationMessage(message) {
      this.notificationMessage = message;
      this.showNotification = true;
      setTimeout(() => {
        this.showNotification = false;
      }, 3000);
    },
    openAgreementModal() {
      console.log(this.agreementContent);
      this.showAgreementModal = true;
    },
    closeAgreementModal() {
      this.showAgreementModal = false;
    },
    // initiatePayment(plan) {
    //     const transactionId = uuidv4();
    //     const paymentData = {
    //         order_id: transactionId,
    //         amount: plan.price,
    //         subject: plan.title,
    //         username: localStorage.getItem('username')
    //     };
    //     console.log("PAY:", paymentData);
    //     axios.post('/api/pay/create', paymentData)
    //         .then(response => {
    //             console.log("1");
    //             console.log("Payment URL:", response.data.paymentUrl);
    //             window.location.href = response.data.paymentUrl;
    //             this.pollPaymentStatus(transactionId, plan); // 开始轮询支付状态
    //         })
    //         .catch(error => {
    //             console.error('初始化支付失败:', error);
    //         });
    // },
    initiatePayment(plan, mentorCode ,apiEndpoint) {
      let transactionId = uuidv4().replace(/-/g, ""); // Removes dashes to make it shorter
      transactionId = transactionId.slice(0, 32); // Ensures it doesn't exceed 32 characters
      const paymentData = {
        plan_id: plan.id,
        order_id: transactionId,
        amount: plan.price,
        subject: plan.title,
        username: localStorage.getItem("username"),
        mentorCode: mentorCode
      };

      axios
        .post(apiEndpoint, paymentData)
        .then((response) => {
          console.log("Payment response:", response.data);
          if (this.selectedPaymentMethod === "alipay") {
            // window.location.href = response.data.paymentUrl; // Redirect for Alipay
            // 在新标签页中打开支付宝支付页面
            window.open(response.data.paymentUrl, '_blank');
            this.pollPaymentStatus(
              transactionId,
              plan,
              this.selectedPaymentMethod
            );
          } else if (this.selectedPaymentMethod === "wechat") {
            // Handle WeChat Pay by displaying the QR code
            this.showWeChatQRCode(response.data.codeUrl);
            this.pollPaymentStatus(
              transactionId,
              plan,
              this.selectedPaymentMethod
            ); // Start polling payment status
          }
        })
        .catch((error) => {
        
          console.error("初始化支付失败:", error);
              // 检查错误响应中的状态码或错误代码
          if (error.response && error.response.status === 1003) {
              // 如果错误代码是 1003，显示提示信息
             this.$message.error("导师推荐码错误！");
            }
        });
    },

    showWeChatQRCode(codeUrl) {
      // Set the selected QR code URL to be used in the modal
      this.qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${encodeURIComponent(
        codeUrl
      )}`;

      // Show the QR code modal
      this.showQRCodeModal = true;
    },
    closeQRCodeModal() {
      if (this.paymentPollingInterval) {
        clearInterval(this.paymentPollingInterval); // 清除轮询定时器
        this.paymentPollingInterval = null; // 重置定时器引用
      }
      this.showQRCodeModal = false;
      this.qrCodeUrl = null;
    },
    pollPaymentStatus(transactionId, plan, selectedPaymentMethod) {
      console.log("Selected Payment Method:", selectedPaymentMethod);
      const username = localStorage.getItem("username");
      this.paymentPollingInterval = setInterval(() => {
        // const endpoint = "/api/pay/query"
        const endpoint =
          selectedPaymentMethod === "alipay"
            ? "/api/pay/query"
            : "/api/wechatpay_query";
        //selectedPaymentMethod === "alipay"
        //? "/api/pay/query"
        // : "/api/wechatpay_query";
        console.log("endpoint:", endpoint);
        console.log("transactionId:", transactionId);
        console.log("username:", username);
        axios
          .post(endpoint, { order_id: transactionId, username: username })
          .then((response) => {
            console.log("支付状态更新成功:", response.data);
            const status = response.data.trade_status;
            if (status === "TRADE_SUCCESS") {
              clearInterval(this.paymentPollingInterval);
              this.refreshAccountStatus();
              this.$set(plan, "paymentStatus", "支付成功");
              this.showNotification("支付成功！感谢您的购买。", "success");
              if (selectedPaymentMethod === "wechat") {
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            } else if (status === "TRADE_FAIL") {
              clearInterval(this.paymentPollingInterval);
              this.$set(plan, "paymentStatus", "支付失败");
              this.showNotification("支付失败，请检查您的支付信息。", "error");
            }
          })
          .catch((error) => {
            console.error("支付状态更新失败:", error);
            clearInterval(this.paymentPollingInterval);
            this.$set(plan, "paymentStatus", "支付状态获取失败");
            this.showNotification(
              "支付状态获取失败，请检查网络连接或稍后重试。",
              "error"
            );
          });
      }, 2000); 
    },
    handlePaymentReturn(orderId, status) {
      console.log(orderId);
      console.log(status);
      const plan = this.plans;
      if (status === "success") {
        this.pollPaymentStatus(orderId, plan); // 调用支付状态轮询函数
      } else if (status === "failed") {
        this.$set(plan, "paymentStatus", "支付失败");
        this.showNotification("支付失败，请检查您的支付信息。", "error");
      }
    },
    refreshAccountStatus() {
      console.log("支付成功，正在刷新账户状态...");
      // 更新账户状态的逻辑
    },
    showNotification(message, type) {
      const notification = document.createElement("div");
      notification.className = `notification ${type}`;
      notification.textContent = message;
      document.body.appendChild(notification);
      setTimeout(() => {
        document.body.removeChild(notification);
      }, 5000);
    },
  },
};
</script>
  <style scoped>
.payment-plans-container {
  color: #333;
  background-color: #f0f0f0;
  padding: 40px 20px;
  font-family: Arial, sans-serif;
}

.header {
  text-align: center;
  margin-bottom: 30px;
}

.header h1 {
  color: #ffd700; /* Gold color */
  font-size: 32px;
  margin-bottom: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Text shadow for a subtle effect */
}

.description {
  color: #000;
  font-size: 16px;
  margin: 10px 0;
  text-align: center; /* Center align the description */
}

.description ul {
  list-style-type: none;
  padding-left: 0;
}

.description li {
  margin-bottom: 10px;
}

.promo-code {
  color: #e60012; /* Highlight promo code text */
  font-size: 18px;
  margin: 10px 0;
  text-align: center;
  font-weight: bold;
  background-color: #fff5f5;
  padding: 10px;
  border-radius: 10px;
}

.plans {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.plan {
  flex: 0 0 calc(33.33% - 20px); /* Each plan takes up 1/3 of the row width minus the gap */
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.plans > .plan:nth-child(4),
.plans > .plan:nth-child(5) {
  flex: 0 0 calc(33.33% - 20px); /* Ensure the last two plans also take up 1/3 of the row width */
}

.plans > .plan:nth-child(4) {
  margin-left: auto; /* Push the fourth plan to the center */
}

.plans > .plan:nth-child(5) {
  margin-right: auto; /* Push the fifth plan to the center */
}

.plan:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.plan-top {
  background-color: #333; /* Dark background for the top part */
  color: #ffd700; /* Gold text color */
  padding: 20px;
  text-align: center;
}

.plan-title {
  font-size: 24px;
  font-weight: bold;
}

.plan-tag {
  font-size: 14px;
  margin-top: 10px;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 15px;
  background-color: #ffd700;
  color: #333;
}

.plan-price {
  text-align: center;
  margin: 20px 0;
}

.current-price {
  font-size: 28px;
  color: #e60012; /* Red color */
  font-weight: bold;
}

.original-price {
  font-size: 16px;
  text-decoration: line-through;
  color: #999;
  margin-left: 10px;
}

.plan-bottom {
  padding: 20px;
  background-color: #fafafa;
}

.time-details {
  font-size: 16px;
  margin-bottom: 10px;
}

.purchase-restriction {
  font-size: 14px;
  color: #666;
  text-align: center;
  margin-top: 10px;
}

.wechat-pay-button {
  text-align: center;
  margin-top: 10px;
}

.wechat-pay-button button {
  background-color: #333;
  border: none;
  color: #e0e0e0;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.3s ease-in-out;
}

.wechat-pay-button button:hover {
  background-color: #555;
}

.qr-code {
  text-align: center;
  margin-top: 10px;
}

.qr-code img {
  width: 200px;
  height: 200px;
  border: 1px solid #333;
  margin-top: 10px;
  border-radius: 10px;
}

.payment-status {
  text-align: center;
  font-size: 14px;
  color: #333;
  margin-top: 10px;
}

.highlight {
  color: #409eff;
  font-size: 28px;
  font-weight: bold;
  animation: highlight-fade 2s infinite; /* Add animation to highlight */
}

@keyframes highlight-fade {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.highlight-text {
  color: #409eff;
  font-weight: bold;
  background-color: #e0f7ff;
  padding: 2px 4px;
  border-radius: 3px;
  transition: background-color 0.3s; /* Add smooth transition */
}

.highlight-text:hover {
  background-color: #b3e5fc; /* Change background color on hover */
}
.notification {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  z-index: 9999;
  transition: opacity 0.5s;
}

.notification.success {
  background-color: #4caf50; /* 绿色 */
}

.notification.error {
  background-color: #f44336; /* 红色 */
}

.notification.info {
  background-color: #2196f3; /* 蓝色 */
}

.payment-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  width: 350px;
}

.modal-title {
  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;
}

.divider {
  border: none;
  border-top: 1px solid #ccc;
  margin-bottom: 20px;
}

.payment-info {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.payment-label {
  flex: 0 0 auto;
  font-weight: bold;
  color: #333;
  margin-right: 10px; /* Reduced margin to minimize gap */
}

.payment-amount,
.payment-icons {
  flex: 1; /* Adjusts to take up remaining space */
  display: flex;
  align-items: center;
}

.payment-icons {
  justify-content: flex-start;
}

.payment-option {
  cursor: pointer;
  padding: 5px;
  margin-left: 10px; /* Adjusted for minimal spacing */
  border: 2px solid transparent;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.payment-option img {
  width: 100px;
  height: 50px;
}

.payment-option.selected {
  border-color: #409eff; /* Highlight color */
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modal-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-buttons button:first-child {
  background-color: #409eff;
  color: white;
}

.modal-buttons button:last-child {
  background-color: #f0f0f0;
  color: #333;
}

.agreement-section {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.agreement-section input {
  margin-right: 5px;
}

.agreement-section a {
  color: #409eff;
  text-decoration: none;
  margin-left: 5px;
  cursor: pointer;
}

.agreement-section a:hover {
  text-decoration: underline;
}

.agreement-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.agreement-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  width: 80%;
  height: 80%;
  overflow-y: auto;
  position: relative;
}

.agreement-content h3 {
  text-align: center;
  margin-bottom: 20px;
}

.agreement-text {
  padding: 10px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
}

.close-btn:hover {
  background-color: #ccc;
}

.notification {
  position: fixed;
  top: 53%;
  left: 60%;
  transform: translateX(-50%); /* Center the text horizontally */
  color: #f44336; /* Red text color */
  font-weight: bold;
  z-index: 99;
}

.agreement-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.agreement-content {
  padding: 20px; /* Add padding inside the content */
  max-height: 70vh; /* Set a maximum height for the content */
  overflow-y: auto; /* Allow vertical scrolling if content overflows */
  white-space: pre-wrap; /* Handle white spaces and line breaks correctly */
  background-color: #fff; /* Ensure a white background for better readability */
  border-radius: 8px; /* Add some rounding to match the modal design */
}

.qr-code-container {
  margin: 20px 0; /* 调整上下边距 */
  text-align: center; /* 居中 QR 码 */
}

.qr-code-container img {
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
}

.qr-code-container .close-qr-code {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.qr-code-container .close-qr-code:hover {
  background-color: #555;
}
</style>
  