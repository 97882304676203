import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import Wechat from "../views/Wechat.vue";
import Login from "@/components/login.vue";
import Register from "@/components/register.vue";
import ArchivePage from "../views/ArchivePage.vue";
import IntervieweeInfo from "../views/IntervieweeInfo.vue";
// import Login from '@/components/Login.vue';
// import Register from '../components/Register.vue';
// import ArchivePage from '../views/ArchivePage.vue/';
// import IntervieweeInfo from '../views/IntervieweeInfo.vue';
import JobPositionInfo from "../views/JobPositionInfo.vue";
import PaymentOptions from "../views/PaymentOptions.vue";
import UserPanel from "../views/UserPanel.vue"; // 导入新的组件
import Layout from "@/layout";
import home from  "../views/Home.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/layout",
    component: Layout,
    redirect: "/layout/home",

    children: [
      {
        path: "home",
        name: "Home",
        component: home,
        meta: { requiresAuth: true },
      },
      {
        path: "homeview",
        name: "HomeView",
        component: HomeView,
        meta: { requiresAuth: true },
      },
      {
        path: "interviewee-info",
        name: "IntervieweeInfo",
        component: IntervieweeInfo,
        meta: { requiresAuth: true },
      },
      {
        path: "jobposition-info",
        name: "JobPositionInfo",
        component: JobPositionInfo,
        meta: { requiresAuth: true },
      },
      {
        path: "payment-options",
        name: "PaymentOptions",
        component: PaymentOptions,
        meta: { requiresAuth: true },
      },
      {
        path: "user-panel", // 新的用户面板路由
        name: "UserPanel",
        component: UserPanel,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/",
    redirect: "/layout/home",
    meta: { requiresAuth: true },
  },
  {
    path: "/setting",
    component: () => import("../views/Setting.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "*",
    redirect: "/layout/home", // 默认重定向到首页
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior: () => ({ y: 0 }),
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = !!localStorage.getItem("auth"); // Ensure this matches what you set in localStorage during login
  if (requiresAuth && !isAuthenticated) {
    next("/login");
  } else if (!requiresAuth && isAuthenticated && to.path === "/login") {
    next("/layout/home"); // Redirect to home if the user is already logged in and tries to access login
  } else {
    next(); // proceed to the route
  }




});

export default router;
