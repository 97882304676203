<template>
  <div class="gangwei">
    <form action="" method="post" @submit.prevent>
      <div
        class="wrap wow fadeInUp"
        data-wow-delay="0.3s"
        data-wow-duration="1s"
      >
        <h2 class="mti"><i>岗位信息</i></h2>
        <div class="flax">
          <div class="lts">
            <div class="ds">
              <h3 class="qmti"><i>公司名称</i></h3>
              <div class="ls">
                <h4>公司名称</h4>
                <input
                  class="t1"
                  type="text"
                  id=""
                  v-model="form.companyName"
                  placeholder="请填写公司名称"
                />
              </div>
            </div>
            <div class="ds">
              <h3 class="qmti"><i>公司信息</i></h3>
              <div class="ls">
                <h4>公司信息</h4>
                <textarea
                  class="t1 t2"
                  placeholder="请填写"
                  v-model="form.companyInfo"
                ></textarea>
              </div>
            </div>
            <div class="ds">
              <h3 class="qmti"><i>岗位信息</i></h3>
              <dl>
                <dd>
                  <h4>行业类型（非必选）</h4>
                  <select
                    name="industryType"
                    class="lsele"
                    v-model="form.industryType"
                    @change="updateSubIndustryOptions"
                  >
                    <option value="">请选择行业类型</option>
                    <option value="互联网/AI">互联网/AI</option>
                    <option value="金融/银行">金融/银行</option>
                    <option value="教育/培训">教育/培训</option>
                    <option value="制造业">制造业</option>
                    <option value="医疗/健康">医疗/健康</option>
                    <option value="电信/通信">电信/通信</option>
                    <option value="房地产/建筑">房地产/建筑</option>
                    <option value="零售/电子商务">零售/电子商务</option>
                    <option value="物流/供应链">物流/供应链</option>
                    <option value="能源/环保">能源/环保</option>
                    <option value="咨询/顾问">咨询/顾问</option>
                    <option value="政府/公共事业">政府/公共事业</option>
                    <option value="法律/法规">法律/法规</option>
                    <option value="广告/媒体">广告/媒体</option>
                    <option value="旅游/酒店">旅游/酒店</option>
                    <option value="汽车/交通运输">汽车/交通运输</option>
                    <option value="农业/食品">农业/食品</option>
                    <option value="艺术/设计">艺术/设计</option>
                    <option value="化工/材料">化工/材料</option>
                    <option value="游戏/娱乐">游戏/娱乐</option>
                    <option value="通信设备/网络">通信设备/网络</option>
                    <option value="航空/航天">航空/航天</option>
                    <option value="生物科技">生物科技</option>
                    <option value="家居/家具">家居/家具</option>
                    <option value="HR/人力资源">HR/人力资源</option>
                    <option value="电子/半导体">电子/半导体</option>
                    <option value="信息技术/服务">信息技术/服务</option>
                    <option value="创业公司">创业公司</option>
                    <option value="文化/体育">文化/体育</option>
                  </select>
                </dd>

                <dd>
                  <h4>细分方向（非必选）</h4>
                  <select
                    name="subIndustry"
                    class="lsele"
                    v-model="form.subIndustry"
                    @change="updateJobTitleOptions"
                  >
                    <option value="">请选择细分方向</option>
                    <option value="人工智能">人工智能</option>
                    <option value="大数据">大数据</option>
                    <option value="云计算">云计算</option>
                    <option value="前端开发">前端开发</option>
                    <option value="后端开发">后端开发</option>
                    <option value="数据分析">数据分析</option>
                    <option value="网络安全">网络安全</option>
                    <option value="产品经理">产品经理</option>
                    <option value="投资银行">投资银行</option>
                    <option value="零售银行">零售银行</option>
                    <option value="风险管理">风险管理</option>
                    <option value="资产管理">资产管理</option>
                    <option value="保险">保险</option>
                    <option value="金融科技">金融科技</option>
                    <option value="审计">审计</option>
                    <option value="税务">税务</option>
                    <option value="K-12教育">K-12教育</option>
                    <option value="高等教育">高等教育</option>
                    <option value="职业教育">职业教育</option>
                    <option value="在线教育">在线教育</option>
                    <option value="特殊教育">特殊教育</option>
                    <option value="成人教育">成人教育</option>
                    <option value="教育技术">教育技术</option>
                    <option value="课程开发">课程开发</option>
                    <option value="汽车制造">汽车制造</option>
                    <option value="电子制造">电子制造</option>
                    <option value="纺织品制造">纺织品制造</option>
                    <option value="食品饮料制造">食品饮料制造</option>
                    <option value="机械制造">机械制造</option>
                    <option value="化工制造">化工制造</option>
                    <option value="航空航天制造">航空航天制造</option>
                    <option value="制药">制药</option>
                    <option value="全科医学">全科医学</option>
                    <option value="外科">外科</option>
                    <option value="儿科">儿科</option>
                    <option value="心脏病学">心脏病学</option>
                    <option value="神经病学">神经病学</option>
                    <option value="肿瘤学">肿瘤学</option>
                    <option value="药学">药学</option>
                    <option value="放射学">放射学</option>
                    <option value="移动通信">移动通信</option>
                    <option value="光纤通信">光纤通信</option>
                    <option value="卫星通信">卫星通信</option>
                    <option value="网络工程">网络工程</option>
                    <option value="物联网">物联网</option>
                    <option value="5G">5G</option>
                    <option value="数据传输">数据传输</option>
                    <option value="通信技术研发">通信技术研发</option>
                    <option value="住宅地产">住宅地产</option>
                    <option value="商业地产">商业地产</option>
                    <option value="物业管理">物业管理</option>
                    <option value="房地产投资">房地产投资</option>
                    <option value="土地开发">土地开发</option>
                    <option value="建筑设计">建筑设计</option>
                    <option value="建设管理">建设管理</option>
                    <option value="城市规划">城市规划</option>
                    <option value="时尚零售">时尚零售</option>
                    <option value="食品零售">食品零售</option>
                    <option value="电子产品零售">电子产品零售</option>
                    <option value="家居用品零售">家居用品零售</option>
                    <option value="美妆零售">美妆零售</option>
                    <option value="奢侈品零售">奢侈品零售</option>
                    <option value="电子商务">电子商务</option>
                    <option value="运动用品零售">运动用品零售</option>
                    <option value="仓储管理">仓储管理</option>
                    <option value="运输管理">运输管理</option>
                    <option value="供应链优化">供应链优化</option>
                    <option value="物流规划">物流规划</option>
                    <option value="国际物流">国际物流</option>
                    <option value="冷链物流">冷链物流</option>
                    <option value="货运代理">货运代理</option>
                    <option value="快递服务">快递服务</option>
                    <option value="可再生能源">可再生能源</option>
                    <option value="传统能源">传统能源</option>
                    <option value="能源管理">能源管理</option>
                    <option value="环境保护">环境保护</option>
                    <option value="水资源管理">水资源管理</option>
                    <option value="废物处理">废物处理</option>
                    <option value="空气质量管理">空气质量管理</option>
                    <option value="环保技术">环保技术</option>
                    <option value="管理咨询">管理咨询</option>
                    <option value="战略咨询">战略咨询</option>
                    <option value="IT咨询">IT咨询</option>
                    <option value="财务咨询">财务咨询</option>
                    <option value="人力资源咨询">人力资源咨询</option>
                    <option value="法律咨询">法律咨询</option>
                    <option value="市场咨询">市场咨询</option>
                    <option value="运营咨询">运营咨询</option>
                    <option value="公共政策">公共政策</option>
                    <option value="公共行政">公共行政</option>
                    <option value="社会服务">社会服务</option>
                    <option value="城市管理">城市管理</option>
                    <option value="公共健康">公共健康</option>
                    <option value="教育管理">教育管理</option>
                    <option value="环境政策">环境政策</option>
                    <option value="国际关系">国际关系</option>
                    <option value="公司法">公司法</option>
                    <option value="知识产权法">知识产权法</option>
                    <option value="劳动法">劳动法</option>
                    <option value="税法">税法</option>
                    <option value="环境法">环境法</option>
                    <option value="国际法">国际法</option>
                    <option value="民法">民法</option>
                    <option value="刑法">刑法</option>
                    <option value="广告策划">广告策划</option>
                    <option value="品牌管理">品牌管理</option>
                    <option value="数字营销">数字营销</option>
                    <option value="内容创作">内容创作</option>
                    <option value="视频制作">视频制作</option>
                    <option value="平面设计">平面设计</option>
                    <option value="媒体策划">媒体策划</option>
                    <option value="公关传播">公关传播</option>
                    <option value="酒店管理">酒店管理</option>
                    <option value="旅游规划">旅游规划</option>
                    <option value="旅行社管理">旅行社管理</option>
                    <option value="景区管理">景区管理</option>
                    <option value="旅游营销">旅游营销</option>
                    <option value="餐饮管理">餐饮管理</option>
                    <option value="会展管理">会展管理</option>
                    <option value="航空服务">航空服务</option>
                    <option value="汽车制造">汽车制造</option>
                    <option value="汽车设计">汽车设计</option>
                    <option value="汽车销售">汽车销售</option>
                    <option value="物流运输">物流运输</option>
                    <option value="公共交通">公共交通</option>
                    <option value="交通规划">交通规划</option>
                    <option value="汽车维修">汽车维修</option>
                    <option value="汽车保险">汽车保险</option>
                    <option value="汽车制造">汽车制造</option>
                    <option value="汽车设计">汽车设计</option>
                    <option value="汽车销售">汽车销售</option>
                    <option value="物流运输">物流运输</option>
                    <option value="公共交通">公共交通</option>
                    <option value="交通规划">交通规划</option>
                    <option value="汽车维修">汽车维修</option>
                    <option value="汽车保险">汽车保险</option>

                    <option
                      v-for="subIndustry in filteredSubIndustries"
                      :key="subIndustry"
                      :value="subIndustry"
                    >
                      {{ subIndustry }}
                    </option>
                  </select>
                </dd>

                <dd>
                  <h4>岗位名称（非必选）</h4>
                  <select name="jobTitle" class="lsele" v-model="form.jobTitle">
                    <option value="">请选择岗位名称</option>
                    <option value="软件工程师">软件工程师</option>
                    <option value="系统管理员">系统管理员</option>
                    <option value="数据分析师">数据分析师</option>
                    <option value="人工智能工程师">人工智能工程师</option>
                    <option value="网络安全专家">网络安全专家</option>
                    <option value="DevOps工程师">DevOps工程师</option>
                    <option value="IT支持工程师">IT支持工程师</option>
                    <option value="云计算工程师">云计算工程师</option>
                    <option value="数据库管理员">数据库管理员</option>
                    <option value="创始人/联合创始人">创始人/联合创始人</option>
                    <option value="产品经理">产品经理</option>
                    <option value="用户体验设计师">用户体验设计师</option>
                    <option value="市场营销经理">市场营销经理</option>
                    <option value="商务拓展经理">商务拓展经理</option>
                    <option value="技术合伙人">技术合伙人</option>
                    <option value="财务总监">财务总监</option>
                    <option value="人力资源经理">人力资源经理</option>
                    <option value="运营经理">运营经理</option>

                    <option value="体育教练">体育教练</option>
                    <option value="体育营销经理">体育营销经理</option>
                    <option value="赛事策划">赛事策划</option>
                    <option value="体育数据分析师">体育数据分析师</option>
                    <option value="文化项目经理">文化项目经理</option>
                    <option value="艺术总监">艺术总监</option>
                    <option value="演出策划">演出策划</option>
                    <option value="制片人">制片人</option>
                    <option value="编辑">编辑</option>
                    <option value="全栈工程师">全栈工程师</option>
                    <option value="前端开发工程师">前端开发工程师</option>
                    <option value="后端开发工程师">后端开发工程师</option>
                    <option value="机器学习工程师">机器学习工程师</option>
                    <option value="数据科学家">数据科学家</option>
                    <option value="产品经理">产品经理</option>
                    <option value="测试工程师">测试工程师</option>
                    <option value="用户界面设计师">用户界面设计师</option>
                    <option value="移动应用开发工程师">
                      移动应用开发工程师
                    </option>
                    <option value="投资分析师">投资分析师</option>
                    <option value="风险管理经理">风险管理经理</option>
                    <option value="资产管理顾问">资产管理顾问</option>
                    <option value="财务分析师">财务分析师</option>
                    <option value="投资银行家">投资银行家</option>
                    <option value="金融顾问">金融顾问</option>
                    <option value="合规经理">合规经理</option>
                    <option value="保险理赔专员">保险理赔专员</option>
                    <option value="贷款经理">贷款经理</option>

                    <option value="教师">教师</option>
                    <option value="教学主管">教学主管</option>
                    <option value="培训师">培训师</option>
                    <option value="课程开发经理">课程开发经理</option>
                    <option value="学术顾问">学术顾问</option>
                    <option value="招生顾问">招生顾问</option>
                    <option value="学生辅导员">学生辅导员</option>
                    <option value="教育技术专员">教育技术专员</option>
                    <option value="教学内容设计师">教学内容设计师</option>

                    <option value="工程师">工程师</option>
                    <option value="生产经理">生产经理</option>
                    <option value="质量控制经理">质量控制经理</option>
                    <option value="供应链经理">供应链经理</option>
                    <option value="设备维护工程师">设备维护工程师</option>
                    <option value="工艺工程师">工艺工程师</option>
                    <option value="项目经理">项目经理</option>
                    <option value="采购经理">采购经理</option>
                    <option value="安全工程师">安全工程师</option>

                    <option value="医生">医生</option>
                    <option value="护士">护士</option>
                    <option value="药剂师">药剂师</option>
                    <option value="医疗技术员">医疗技术员</option>
                    <option value="医学研究员">医学研究员</option>
                    <option value="临床协调员">临床协调员</option>
                    <option value="健康顾问">健康顾问</option>
                    <option value="放射科医生">放射科医生</option>
                    <option value="公共卫生专家">公共卫生专家</option>

                    <option value="通信工程师">通信工程师</option>
                    <option value="网络工程师">网络工程师</option>
                    <option value="无线通信专家">无线通信专家</option>
                    <option value="5G工程师">5G工程师</option>
                    <option value="技术支持工程师">技术支持工程师</option>
                    <option value="系统架构师">系统架构师</option>
                    <option value="网络管理员">网络管理员</option>
                    <option value="通信技术研发工程师">
                      通信技术研发工程师
                    </option>
                    <option value="设备安装维护工程师">
                      设备安装维护工程师
                    </option>

                    <option value="房地产经纪人">房地产经纪人</option>
                    <option value="物业经理">物业经理</option>
                    <option value="建筑工程师">建筑工程师</option>
                    <option value="室内设计师">室内设计师</option>
                    <option value="项目经理">项目经理</option>
                    <option value="城市规划师">城市规划师</option>
                    <option value="建筑设计师">建筑设计师</option>
                    <option value="土木工程师">土木工程师</option>
                    <option value="房地产投资顾问">房地产投资顾问</option>

                    <option value="销售经理">销售经理</option>
                    <option value="电子商务经理">电子商务经理</option>
                    <option value="商品采购专员">商品采购专员</option>
                    <option value="市场营销经理">市场营销经理</option>
                    <option value="客户服务经理">客户服务经理</option>
                    <option value="品牌经理">品牌经理</option>
                    <option value="运营经理">运营经理</option>
                    <option value="仓储管理专员">仓储管理专员</option>
                    <option value="物流经理">物流经理</option>

                    <option value="供应链经理">供应链经理</option>
                    <option value="物流经理">物流经理</option>
                    <option value="仓储管理专员">仓储管理专员</option>
                    <option value="运输经理">运输经理</option>
                    <option value="货运代理">货运代理</option>
                    <option value="库存管理员">库存管理员</option>
                    <option value="采购专员">采购专员</option>
                    <option value="订单处理专员">订单处理专员</option>
                    <option value="物流规划专员">物流规划专员</option>

                    <option value="能源管理工程师">能源管理工程师</option>
                    <option value="环保工程师">环保工程师</option>
                    <option value="环境顾问">环境顾问</option>
                    <option value="项目经理">项目经理</option>
                    <option value="水资源管理专员">水资源管理专员</option>
                    <option value="废物处理工程师">废物处理工程师</option>
                    <option value="环境影响评估专家">环境影响评估专家</option>
                    <option value="可再生能源工程师">可再生能源工程师</option>
                    <option value="安全与健康专家">安全与健康专家</option>

                    <option value="管理顾问">管理顾问</option>
                    <option value="IT顾问">IT顾问</option>
                    <option value="财务顾问">财务顾问</option>
                    <option value="人力资源顾问">人力资源顾问</option>
                    <option value="营销顾问">营销顾问</option>
                    <option value="法律顾问">法律顾问</option>
                    <option value="战略顾问">战略顾问</option>
                    <option value="审计师">审计师</option>
                    <option value="项目管理顾问">项目管理顾问</option>

                    <option value="公共政策分析师">公共政策分析师</option>
                    <option value="行政管理专员">行政管理专员</option>
                    <option value="社会服务专员">社会服务专员</option>
                    <option value="公共健康专家">公共健康专家</option>
                    <option value="城市规划师">城市规划师</option>
                    <option value="环境政策顾问">环境政策顾问</option>
                    <option value="公共关系经理">公共关系经理</option>
                    <option value="教育管理专员">教育管理专员</option>
                    <option value="国际关系顾问">国际关系顾问</option>
                    <option
                      v-for="jobTitle in filteredJobTitles"
                      :key="jobTitle"
                      :value="jobTitle"
                    >
                      {{ jobTitle }}
                    </option>
                  </select>
                </dd>

                <dd>
                  <h4>岗位信息</h4>
                  <textarea
                    class="t1 t2"
                    placeholder="请填写"
                    v-model="form.jobInfo"
                  ></textarea>
                </dd>
              </dl>
              <input type="submit" class="btn" value="保存" @click="saveInfo" />
            </div>
          </div>
          <div class="rts">
            <h3 class="qmti"><i>岗位信息</i></h3>
            <div class="bds">
              <ul>
                <li>
                  <a href="images/qms2.jpg">
                    <img class="pic" src="images/qms2.jpg" alt="" />
                    <div class="ico">
                      <img src="images/qms1.png" alt="" />
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "gangwei",

  data() {
    return {
      form: {
        username: localStorage.getItem("username"),
        companyName: "",
        companyInfo: "",
        industryType: "",
        subIndustry: "",
        jobTitle: "",
        jobInfo: "",
      },
      subIndustries: {
        "互联网/AI": [
          "前端开发",
          "后端开发",
          "人工智能",
          "数据科学",
          "网络安全",
          "大数据分析",
          "云计算",
          "区块链技术",
          "移动应用开发",
        ],
        "金融/银行": [
          "投资银行",
          "风险管理",
          "财富管理",
          "金融技术",
          "保险科技",
          "商业银行",
          "支付系统",
        ],
        "教育/培训": [
          "在线教育",
          "职业培训",
          "K12教育",
          "高等教育",
          "幼儿教育",
          "成人教育",
        ],
        制造业: [
          "机械制造",
          "汽车制造",
          "电子制造",
          "航空制造",
          "食品加工",
          "化学工程",
        ],
        "医疗/健康": [
          "医院管理",
          "医药研发",
          "健康管理",
          "公共健康",
          "生物信息学",
          "医疗设备研发",
        ],
        "电信/通信": [
          "移动通信",
          "卫星通信",
          "网络工程",
          "光通信",
          "物联网",
          "5G技术",
        ],
        "房地产/建筑": [
          "建筑设计",
          "城市规划",
          "房地产开发",
          "土木工程",
          "室内设计",
          "物业管理",
        ],
        "零售/电子商务": [
          "供应链管理",
          "市场营销",
          "客户关系管理",
          "电子支付",
          "物流管理",
        ],
        "能源/环保": [
          "可再生能源",
          "能源管理",
          "环境监测",
          "水处理",
          "废物管理",
        ],
        "咨询/顾问": ["战略咨询", "IT咨询", "管理咨询", "财务咨询", "法律顾问"],
        // 更多行业的细分方向...
      },
      jobTitles: {
        前端开发: ["前端开发工程师", "前端架构师", "UI开发工程师"],
        后端开发: ["后端开发工程师", "后端架构师", "API开发工程师"],
        人工智能: [
          "机器学习工程师",
          "深度学习工程师",
          "AI研究员",
          "自然语言处理工程师",
        ],
        数据科学: ["数据分析师", "数据科学家", "数据工程师"],
        网络安全: ["网络安全专家", "信息安全工程师", "安全测试工程师"],
        大数据分析: ["大数据工程师", "数据挖掘工程师", "数据分析专家"],
        云计算: ["云计算架构师", "云运维工程师", "DevOps工程师"],
        区块链技术: ["区块链开发工程师", "智能合约开发工程师", "区块链架构师"],
        移动应用开发: ["iOS开发工程师", "Android开发工程师", "移动架构师"],
        投资银行: ["投资银行家", "并购顾问", "投行分析师"],
        风险管理: ["风险控制经理", "合规专员", "信用分析师"],
        财富管理: ["财富顾问", "私人银行顾问", "理财规划师"],
        金融技术: ["FinTech开发工程师", "金融数据分析师", "支付系统架构师"],
        保险科技: ["保险产品经理", "保险数据分析师", "保险技术开发工程师"],
        商业银行: ["商业银行客户经理", "信贷分析师", "银行运营专员"],
        支付系统: ["支付系统开发工程师", "支付安全工程师", "支付产品经理"],
        在线教育: ["课程设计师", "在线教育顾问", "教学设计师"],
        职业培训: ["职业培训师", "企业培训师", "培训项目经理"],
        K12教育: ["K12教师", "教育产品经理", "教育技术开发工程师"],
        高等教育: ["高校讲师", "研究员", "学术顾问"],
        幼儿教育: ["幼儿教师", "幼教课程设计师", "幼儿教育顾问"],
        成人教育: ["成人教育顾问", "职业教育导师", "继续教育顾问"],
        机械制造: ["机械工程师", "设备工程师", "制造工艺工程师"],
        汽车制造: ["汽车工程师", "汽车设计师", "动力系统工程师"],
        电子制造: ["电子工程师", "嵌入式系统工程师", "电路设计工程师"],
        航空制造: ["航空工程师", "飞行器设计师", "航空制造工艺师"],
        食品加工: ["食品工程师", "质量控制经理", "食品技术专家"],
        化学工程: ["化学工程师", "工艺工程师", "材料工程师"],
        医院管理: ["医院管理者", "医疗运营经理", "医院信息系统管理员"],
        医药研发: ["药物研发工程师", "临床研究员", "药物分析师"],
        健康管理: ["健康咨询师", "营养师", "康复治疗师"],
        公共健康: ["公共卫生专家", "健康政策顾问", "健康教育专员"],
        生物信息学: ["生物信息学家", "基因组分析师", "生物数据科学家"],
        医疗设备研发: [
          "医疗设备工程师",
          "医疗技术研发经理",
          "产品经理（医疗设备）",
        ],
        移动通信: ["移动通信工程师", "无线网络优化工程师", "通信基站工程师"],
        卫星通信: ["卫星通信工程师", "卫星系统设计师", "卫星运营工程师"],
        网络工程: ["网络工程师", "网络架构师", "网络运维工程师"],
        光通信: ["光通信工程师", "光纤设计师", "光网络优化工程师"],
        物联网: ["物联网工程师", "物联网架构师", "嵌入式系统工程师（IoT）"],
        "5G技术": ["5G研发工程师", "无线通信工程师", "5G网络架构师"],
        建筑设计: ["建筑设计师", "项目建筑师", "建筑制图师"],
        城市规划: ["城市规划师", "土地使用规划师", "交通规划师"],
        房地产开发: ["房地产开发经理", "项目经理（房地产）", "房地产分析师"],
        土木工程: ["土木工程师", "结构工程师", "地基工程师"],
        室内设计: ["室内设计师", "装潢设计师", "空间规划师"],
        物业管理: ["物业经理", "物业顾问", "物业运营专员"],
        供应链管理: ["供应链经理", "物流规划师", "采购经理"],
        市场营销: ["市场营销经理", "品牌经理", "数字营销专员"],
        客户关系管理: ["CRM经理", "客户服务经理", "客户关系分析师"],
        电子支付: ["支付系统架构师", "支付产品经理", "支付安全工程师"],
        物流管理: ["物流经理", "运输协调员", "仓储经理"],
        可再生能源: ["可再生能源工程师", "能源政策分析师", "太阳能工程师"],
        能源管理: ["能源管理顾问", "能源审计师", "能源项目经理"],
        环境监测: ["环境监测员", "环境数据分析师", "环境科学家"],
        水处理: ["水处理工程师", "污水处理技术员", "水质分析师"],
        废物管理: ["废物管理专家", "回收技术员", "废物处理工程师"],
        战略咨询: ["战略顾问", "企业发展顾问", "市场策略师"],
        IT咨询: ["IT顾问", "信息系统分析师", "数字化转型顾问"],
        管理咨询: ["管理顾问", "业务流程顾问", "组织变革顾问"],
        财务咨询: ["财务顾问", "税务顾问", "财务分析师"],
        法律顾问: ["法律顾问", "合同法专家", "公司法务"],
        // 更多细分方向对应的岗位名称...
      },
    };
  },
  computed: {
    filteredSubIndustries() {
      return this.subIndustries[this.form.industryType] || [];
    },
    filteredJobTitles() {
      return this.jobTitles[this.form.subIndustry] || [];
    },
  },
  methods: {
    saveInfo() {
      const username = localStorage.getItem("username");
      console.log("username", username);
      if (!username) {
        this.$message.error("用户名未设置");
        return;
      }
      const apiUrl = `/api/jobpositions/${username}`;
      const method = "put";

      axios[method](apiUrl, this.form)
        .then((response) => {
          this.$message.success("信息保存成功");
        })
        .catch((error) => {
          console.error("保存失败", error);
          this.$message.error("信息保存失败");
        });
    },
    updateSubIndustryOptions() {
      this.form.subIndustry = "";
      this.form.jobTitle = "";
    },
    updateJobTitleOptions() {
      this.form.jobTitle = "";
    },
  },
  mounted() {
    baguetteBox.run(".gangwei", {
      animation: "fadeIn",
    });
    const username = localStorage.getItem("username");
    console.log("username", username);
    if (username) {
      axios
        .get(`/api/jobpositions/${username}`)
        .then((response) => {
          this.form = response.data;
        })
        .catch((error) => {
          console.error("获取岗位信息失败", error);
        });
    }
  },
};
</script>

<style scoped >
.gangwei {
  height: 1100px;
  flex: 1; /* 使 .gangwei 充满剩余的空间 */
  padding-bottom: 50px; /* 给底部栏留出空间，假设底部栏高度为50px */
}
.flax{
  height: 1000px;
}
</style>
