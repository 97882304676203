<template>
    <el-menu-item>
        <el-button
            type="danger"
            @click="handleLogout"
            :loading="logout_loading"
            :disabled="logout_loading"
            class="logout-button">
            退出登录
        </el-button>
    </el-menu-item>
</template>

<script>
export default {
    name: 'LogoutButton',
    methods: {
        handleLogout() {
            this.$emit('logout');  // Emit an event when logout is triggered
        }
    }
}
</script>

<style scoped>
.logout-button {
    background-color: #f56c6c; /* Red color for danger */
    color: white; /* White text color */
    width: 100px; /* Adjust width as needed */
    height: 40px; /* Adjust height as needed */
    border-radius: 5px; /* Slightly rounded corners */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px; /* Adjust font size as needed */
    border: none; /* Remove default border */
}

.logout-button:hover {
    background-color: #dd5656; /* Darker shade on hover */
}

.logout-button:focus, .logout-button:active {
    background-color: #d04444; /* Even darker for active state */
}
</style>
