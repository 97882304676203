<template>
  <div class="gangwei">
    <form action="" method="post" @submit.prevent>
      <div
        class="wrap wow fadeInUp"
        data-wow-delay="0.3s"
        data-wow-duration="1s"
      >
        <h2 class="mti"><i>面试者信息</i></h2>
        <div class="flax">
          <div class="lts">
            <div class="ds">
              <h3 class="qmti"><i>简历附件</i></h3>
              <div class="flax2">
                <div class="file">
                  <el-upload
                    class="upload-demo"
                    :action="uploadUrl"
                    :on-success="handleImageUpload"
                    :on-error="handleImageUploadError"
                    :on-progress="handleImageUploadProgress"
                    :before-upload="beforeUpload"
                    :headers="uploadHeaders"
                    :file-list="fileList"
                    :accept="'.pdf'"
                    list-type="picture"
                  >
                    <div>
                      <!-- <input class="pors" type="file" name="" id="" /> -->
                      <img src="images/fpic1.jpg" alt="" />
                      <div class="btna">上传文件</div>
                    </div>
                  </el-upload>
                  <el-progress :percentage="uploadProgress"></el-progress>
                </div>
                <h6>*仅限PDF文件上传</h6>
              </div>
            </div>
            <div class="ds">
              <h3 class="qmti"><i>基本信息</i></h3>
              <div class="polist">
                <div class="ls">
                  <h4>姓名</h4>
                  <input
                    class="t1"
                    type="text"
                    id=""
                    v-model="form.name"
                    placeholder="请填写您的姓名"
                  />
                </div>
                <div class="ls">
                  <h4>性别</h4>
                  <div class="flax3">
                    <input
                      type="radio"
                      id="male"
                      class="ras"
                      name="gender"
                      value="male"
                      v-model="form.gender"
                    />
                    <label for="male">男</label>
                    <input
                      type="radio"
                      id="female"
                      class="ras"
                      name="gender"
                      value="female"
                      v-model="form.gender"
                    />
                    <label for="female">女</label>
                  </div>
                </div>
                <div class="ls">
                  <h4>年龄</h4>
                  <input
                    class="t1"
                    type="text"
                    id=""
                    v-model="form.age"
                    placeholder="请填写您的年龄"
                  />
                </div>
                <div class="ls">
                  <h4>手机号码</h4>
                  <input
                    class="t1"
                    type="text"
                    id=""
                    v-model="form.phone"
                    placeholder="请填写您的手机号码"
                  />
                </div>
                <div class="ls">
                  <h4>邮箱</h4>
                  <input
                    class="t1"
                    type="text"
                    id=""
                    v-model="form.mail"
                    placeholder="请填写您的邮箱"
                  />
                </div>
              </div>
            </div>
            <div class="ds">
              <h3 class="qmti"><i>教育经历</i></h3>
              <div class="ls">
                <h4>描述</h4>
                <textarea
                  class="t1 t2"
                  placeholder="请填写"
                  v-model="form.education"
                ></textarea>
              </div>
            </div>
            <div class="ds">
              <h3 class="qmti"><i>实习经历</i></h3>
              <div class="ls">
                <h4>描述</h4>
                <textarea
                  class="t1 t2"
                  placeholder="请填写"
                  v-model="form.internship"
                ></textarea>
              </div>
            </div>
            <div class="ds">
              <h3 class="qmti"><i>专业技能</i></h3>
              <div class="ls">
                <h4>描述</h4>
                <textarea
                  class="t1 t2"
                  placeholder="请填写"
                  v-model="form.skills"
                ></textarea>
              </div>
            </div>
            <div class="ds">
              <h3 class="qmti"><i>补充信息</i></h3>
              <div class="ls">
                <h4>描述</h4>
                <textarea
                  class="t1 t2"
                  placeholder="请填写"
                  v-model="form.additionalInfo"
                ></textarea>
              </div>
              <input
                type="submit"
                class="btn"
                name=""
                id=""
                value="保存"
                @click="saveInfo"
              />
            </div>
          </div>
          <div class="rts">
            <h3 class="qmti"><i>岗位信息</i></h3>
            <div class="bds">
              <ul>
                <li>
                  <a href="2_demo/fpic2.png">
                    <img class="pic" src="2_demo/fpic2.png" alt="" />
                    <div class="ico">
                      <img src="images/qms1.png" alt="" />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="2_demo/fpic3.png">
                    <img class="pic" src="2_demo/fpic3.png" alt="" />
                    <div class="ico">
                      <img src="images/qms1.png" alt="" />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="2_demo/fpic4.png">
                    <img class="pic" src="2_demo/fpic4.png" alt="" />
                    <div class="ico">
                      <img src="images/qms1.png" alt="" />
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "form",

  data() {
    return {
      form: {
        username: localStorage.getItem("username") || "未登录",
        name: "",
        gender: "",
        age: "",
        education: "",
        skills: "",
        internship: "",
        additionalInfo: "",
      },
      fileList: [],
      images: [
        // 使用绝对路径加载图片
        "2_demo/fpic2.png",
        "2_demo/fpic3.png",
        "2_demo/fpic4.png",
      ],
      uploadProgress: 0, // 添加上传进度
      uploadInterval: null, // 添加定时器
    };
  },
  mounted() {
    baguetteBox.run(".gangwei", {
      animation: "fadeIn",
    });
    const username = this.form.username;
    if (username && username !== "未登录") {
      // this.uploadUrl = `/api/upload/${username}`;

      axios
        .get(`/api/interviewees/${username}`)
        .then((response) => {
          this.form = response.data;
        })
        .catch((error) => {
          console.error("获取面试者信息失败", error);
        });
    }
  },
  computed: {
    uploadUrl() {
      return `/api/upload/${this.form.username}`;
    },
    uploadHeaders() {
      return {
        "X-Username": this.form.username,
        "token": localStorage.getItem("auth")
      };
    },
  },
  components: {},
  methods: {
    beforeUpload(file) {
      const isPDF = file.type === "application/pdf";
      if (!isPDF) {
        this.$message.error("上传文件格式不正确，仅限PDF文件");
      }
      return isPDF;
    },
    handleImageUpload(response, file) {
      console.log("文件上传成功", response);
      clearInterval(this.uploadInterval); // 停止定时器
      this.uploadProgress = 100; // 设置进度为100%
      this.$message.success("文件解析成功，请刷新页面查看最新信息");
      this.$alert("文件解析成功，请刷新页面查看最新信息", "成功", {
        confirmButtonText: "确定",
        type: "success",
      });
    },
    handleImageUploadError(err, file) {
      console.error("文件上传失败", err);
      this.$alert("文件解析失败，请查看Token余额是否充足并重试", "错误", {
        confirmButtonText: "确定",
        type: "error",
      });
    },
    handleImageUploadProgress(event, file) {
      this.uploadProgress = Math.round((event.loaded * 100) / event.total);
      if (this.uploadProgress === 100) {
        this.$message({
          message: "文件上传成功，将会在一分钟之内完成解析，请稍候",
          type: "info",
          duration: 3000, // 提示消息持续3秒
        });
      }
    },
    saveInfo() {
      const username = this.form.username;
      const apiUrl = `/api/interviewees/${username}`;
      const method = "put";

      axios[method](apiUrl, this.form)
        .then((response) => {
          this.$message({
            message: "信息保存成功",
            type: "success",
            duration: 1000, // 设置显示时间为 1 秒
          });
          setTimeout(() => {
            // this.$router.push("/archive");
          }, 1000); // 等待消息消失后再导航
        })
        .catch((error) => {
          console.error("保存失败", error);
          this.$message.error("信息保存失败");
        });
    },
  },
};
</script>
<style scoped >


.gangwei {
  height: 2100px;
  flex: 1; /* 使 .gangwei 充满剩余的空间 */
  padding-bottom: 50px; /* 给底部栏留出空间，假设底部栏高度为50px */
}
.flax{
  height: 2000px;
}
</style>
