<template>
  <div class="container">
    <div class="background"></div>
    <div class="content">
      <div class="ad-section">
        <h1>AI面试助手</h1>
        <p>您的专属AI面试助手，助您在面试中脱颖而出！</p>
        <p>智能分析，精准建议，提升您的面试成功率。</p>
        <p>立即登录，体验前所未有的面试准备方式！</p>
      </div>
      <div class="login-box">
        <h2>登录</h2>
        <p class="sub-text">密码应为8到16个字符之间。</p>
        <el-form
          ref="loginForm"
          :model="loginForm"
          :rules="rules"
          label-width="80px"
        >
          <el-form-item label="用户名" prop="username">
            <el-input
              v-model="loginForm.username"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <div class="password-container">
              <el-input
                :type="showPassword ? 'text' : 'password'"
                v-model="loginForm.password"
                placeholder="请输入密码"
              ></el-input>
              <button
                type="button"
                @click="togglePasswordVisibility"
                class="eye-button"
              >
                <i
                  :class="showPassword ? 'el-icon-view-off' : 'el-icon-view'"
                ></i>
              </button>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleLogin">登录</el-button>
          </el-form-item>
        </el-form>
        <p class="register-link">
          <router-link to="/register">没有账户？注册</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Login",
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
      },
      showPassword: false,
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          if (
            this.loginForm.password.length < 8 ||
            this.loginForm.password.length > 16
          ) {
            this.$message.error("密码应为8到16个字符之间");
            return;
          }
          // 测试账号
          const testUser = {
            username: "testuser",
            password: "12345678",
          };

          // 检查是否是测试账号
          if (
            this.loginForm.username === testUser.username &&
            this.loginForm.password === testUser.password
          ) {
            const sessionExpiration = new Date().getTime() + 9 * 60 * 60 * 1000;
            localStorage.setItem("auth", "fake-jwt-token");
            localStorage.setItem("username", this.loginForm.username);
            localStorage.setItem("sessionExpiration", sessionExpiration);
            axios.defaults.headers.common["X-Username"] =
              this.loginForm.username; // Set X-Username header
            this.$router.push({ name: "Home" });
          } else {
            // 尝试连接后端
            axios
              .post("/api/login", this.loginForm, { withCredentials: true })
              .then((response) => {
                const sessionExpiration =
                  new Date().getTime() + 9 * 60 * 60 * 1000; // 9 hours in milliseconds
                localStorage.setItem("auth", response.data.token);
                localStorage.setItem("username", this.loginForm.username);
                localStorage.setItem("sessionExpiration", sessionExpiration);
                axios.defaults.headers.common["X-Username"] =
                  this.loginForm.username;
                axios.defaults.headers.common["token"] =
                  localStorage.getItem("auth");
                 axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("auth")}`;
                this.$router.push({ name: "Home" });
              })
              .catch((error) => {
                this.$message.error("用户名或密码错误");
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  mounted() {
    // 禁止滚动
    document.body.style.overflow = "hidden";
    // Set X-Username header for all requests after login
    const username = localStorage.getItem("username");
    if (username) {
      axios.defaults.headers.common["X-Username"] = username;
      axios.defaults.headers.common["token"] = localStorage.getItem("auth");
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("auth")}`;
    }
  },
  beforeDestroy() {
    // 恢复滚动
    document.body.style.overflow = "";
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");

body {
  font-family: "Open Sans", sans-serif;
  background-color: #f8f8f8;
  overflow: hidden; /* 禁止滚动 */
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("demo_new.jpg") no-repeat center center fixed;
  background-size: cover;
  filter: blur(2px); /* 调整模糊效果 */
  z-index: -1;
}

.content {
  display: flex;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  max-width: 900px;
  width: 100%;
  position: relative;
  z-index: 1;
}

.ad-section {
  padding: 40px;
  background: #2c3e50;
  color: black;
  display: flex;
  flex-direction: column;
  background: -webkit-linear-gradient(left, #fdec54, #ffb64e);
  justify-content: center;
  flex: 1;
  font-weight: 600;
}

.ad-section h1 {
  margin-bottom: 20px;
  font-size: 24px;
}

.ad-section p {
  margin: 10px 0;
}

.login-box {
  padding: 40px;
  width: 400px;
  flex: 1;
  background-color: #262626;
}
.login-box h2{
    font-size: 2rem;
}
.login-box  ::v-deep .el-form-item__label {
    color: white;
}
.login-box *{
    color: white !important;
}

h2 {
  text-align: center;
  margin-bottom: 30px;
  color: #2c3e50;
}

.sub-text {
  text-align: center;
  margin-bottom: 20px;
  color: #999;
}

.password-container {
  display: flex;
  align-items: center;
}

.eye-button {
  background: none;
  border: none;
  margin-left: 10px;
  cursor: pointer;
}

.register-link {
  text-align: center;
  margin-top: 20px;
}
</style>
