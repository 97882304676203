<template>
  <div class="my">
    <div class="hd wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="1s">
      <div class="wrap">
        <div class="name">
          <img :src="avatarUrl" alt="" />
          <h4>{{ username }}</h4>
        </div>
        <h5>
          剩余普通Token数量: <i>{{ form.remainNormalToken }}个</i>
        </h5>
        <h5>
          剩余高级Token数量: <i>{{ form.remainAdvanceToken }}个</i>
        </h5>
        <h5>
          剩余使用时间: <i>{{ form.remainTime }}分钟</i>
        </h5>
        <h5>
          会员卡到期日: <i>{{ form.membershipEnd }}</i>
        </h5>

        <a class="bt" @click="centerDialogVisible = true">购买token</a>
      </div>
    </div>
    <div
      class="bd wrap wow fadeInUp"
      data-wow-delay="0.3s"
      data-wow-duration="1s"
    >
      <div class="lts">
        <div class="item">
          <ul>
            <li>
              <h3><i>基本信息</i></h3>
              <dl>
                <dd>
                  <h4>姓名</h4>
                  <h5>{{ form.name }}</h5>
                </dd>
                <dd>
                  <h4>性别</h4>
                  <h5>{{ genderDisplay }}</h5>
                </dd>
                <dd>
                  <h4>年龄</h4>
                  <h5>{{ form.age }}</h5>
                </dd>
              </dl>
            </li>
            <li>
              <h3><i>教育经历</i></h3>
              <div class="boxscll">
                {{ form.education }}
              </div>
            </li>
            <li>
              <h3><i>专业技能</i></h3>
              <div class="boxscll">
                {{ form.skills }}
              </div>
            </li>
            <li>
              <h3><i>实习经历</i></h3>
              <div class="boxscll">
                {{ form.internship }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="lts">
        <div class="item">
          <ul>
            <li>
              <h3><i>岗位信息</i></h3>
              <div class="txt">
                {{ form.jobInfo }}
              </div>
            </li>
            <li>
              <h3><i>公司信息</i></h3>
              <dl>
                <dd>
                  <h4>公司名称</h4>
                  <h5>{{ form.companyName }}</h5>
                </dd>
                <dd>
                  <h4>公司信息</h4>
                  <h5>
                    {{ form.companyInfo }}
                  </h5>
                </dd>
              </dl>
            </li>
            <li>
              <h3><i>其他信息</i></h3>
              <dl>
                <dd>
                  <h4>面试语言</h4>
                  <h5>{{ languageDisplay }}</h5>
                </dd>
                <dd>
                  <h4>补充信息</h4>
                  <h5>
                    {{ form.additionalInfo }}
                  </h5>
                </dd>
              </dl>
            </li>
          </ul>
        </div>
        <div class="item item2">
          <h6>以下设置仅在您的浏览器中本地保留。</h6>
          <h3><i>面试语言选择</i></h3>
          <select class="lsele" v-model="language" placeholder="选择识别语言">
            <option label="中文" value="zh-CN"></option>
            <option label="英文" value="en-US"></option>
          </select>
          <div class="btn">
            <a @click="saveSettings" class="on">保存设置</a>
            <a @click="resetSettings">重置设置</a>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="token购买"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <div>
        <el-form
          :model="shopForm"
          ref="shopForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="购买种类" prop="tokenType">
            <el-radio-group v-model="shopForm.tokenType" size="medium">
              <el-radio-button
                v-for="token in tokens"
                :label="token"
                :key="token"
                >{{ token }}</el-radio-button
              >
            </el-radio-group>
          </el-form-item>

          <el-form-item label="购买数量" prop="nums">
            <el-input-number
              v-model="shopForm.nums"
              :min="1"
              :max="999"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="金额" prop="money">
            <span>{{ tokenIntro }}</span>
          </el-form-item>

        </el-form>

        <div class="payment-info">
          <p class="payment-label">支付平台:</p>
          <div class="payment-icons">
            <div
              class="payment-option"
              :class="{ selected: selectedPaymentMethod === 'alipay' }"
              @click="selectPaymentMethod('alipay')"
            >
              <img src="@/assets/alipay-icon.jpeg" alt="支付宝" />
            </div>
            <div
              Interview-ai123
              class="payment-option"
              :class="{ selected: selectedPaymentMethod === 'wechat' }"
              @click="selectPaymentMethod('wechat')"
            >
              <img src="@/assets/wechatpay-icon.jpeg" alt="微信" />
            </div>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="processPayment"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <div v-if = "showQRCodeModal" class="payment-modal">
      <div class="modal-content">
        <h3 class="modal-title">微信支付二维码</h3>
        <hr class="divider" />
        <div class="qr-code-container">
          <img :src="qrCodeUrl" alt="WeChat Pay QR Code" />
        </div>
        <div class="modal-buttons">
          <button @click="verifyPayment">完成支付</button>
          <button @click="closeQRCodeModal">关闭</button>
        </div>
      </div>
   
    </div>
    <div v-if="showNotification" class="notification">
      {{ notificationMessage }}
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
export default {
  name: "UserPanel",
  components: {},
  data() {
    return {
      shopForm: {
        tokenType: "高级token",
        money: "1块1000token",
        nums: 1,
      },
      language: localStorage.getItem("language") || "zh-CN", // Default to Chinese if no setting is stored
      username: localStorage.getItem("username") || "未登录",
      remainAdvanceToken: 0,
      remainNormalToken: 0,
      tokens: ["高级token", "普通token"],

      avatarUrl: "2_demo/base.png",
      form: {
        name: "",
        gender: "",
        age: "",
        education: "",
        skills: "",
        internship: "",
        companyName: "",
        companyInfo: "",
        jobInfo: "",
        language: "",
        additionalInfo: "",
        membershipEnd : 0,
      },
      centerDialogVisible: false,
      selectedPaymentMethod: null,
      showQRCodeModal: false,
      showTokenBuy:true,
      paymentPollingInterval: null, // 存储轮询定时器的引用
      transactionId:"",
      notificationMessage: "",
    };
  },
  computed: {
    tokenIntro(){
      const len = this.shopForm.nums
      const obj = {
        "高级token": `${len}块${1000 * len}token`,
        "普通token":  `${len}块${2000 * len}token`,
      }
        return obj[this.shopForm.tokenType]
    },
    languageDisplay() {
      return this.form.language === "中文"
        ? "中文"
        : this.form.language === "英文"
        ? "英文"
        : "未设置";
    },
    genderDisplay() {
      return this.form.gender === "male" ? "男" : this.form.gender === "female" ? "女" : "未设置";
    },
  },
  methods: {
    processPayment() {
      console.log(123123);
      if (this.selectedPaymentMethod === "alipay") {
        this.initiatePayment(this.shopForm,"/api/pay/create");
      } else if (this.selectedPaymentMethod === "wechat") {
        this.initiatePayment(this.shopForm, "/api/wechatpay_create");
      } else {
        this.$message.error("请选择支付方式，且阅读并同意《会员服务协议》");
      }
    },
    verifyPayment() {
      if (this.paymentPollingInterval) {
        clearInterval(this.paymentPollingInterval); // 清除轮询定时器
        this.paymentPollingInterval = null; // 重置定时器引用
      }
      // Show a notification to the user
      this.showNotificationMessage("正在验证您的支付，请稍后", "info");

      // Query the payment status
      this.pollPaymentStatus(
        this.transactionId,
        this.selectedPaymentMethod
      );
    },

    showNotificationMessage(message) {
      this.notificationMessage = message;
      this.showNotification = true;
      setTimeout(() => {
        this.showNotification = false;
      }, 3000);
    },

    closeQRCodeModal() {
      if (this.paymentPollingInterval) {
        clearInterval(this.paymentPollingInterval); // 清除轮询定时器
        this.paymentPollingInterval = null; // 重置定时器引用
      }
      this.showQRCodeModal = false;
      this.qrCodeUrl = null;
    },
    initiatePayment(shopForm , apiEndpoint) {
      let transactionId = uuidv4().replace(/-/g, ""); // Removes dashes to make it shorter
      this.transactionId = transactionId.slice(0, 32); // Ensures it doesn't exceed 32 characters
      const paymentData = {
        // plan_id: plan.id,
        order_id: this.transactionId,
        amount: shopForm.nums,
        subject: "Token 购买",
        username: localStorage.getItem("username"),
        is_token: shopForm.tokenType === "高级token" ? "11" : "22",
        // mentorCode: mentorCode
      };

      axios
        .post(apiEndpoint, paymentData)
        .then((response) => {
          console.log("Payment response:", response.data);
      
          if (this.selectedPaymentMethod === "alipay") {
            window.location.href = response.data.paymentUrl; // Redirect for Alipay
            this.pollPaymentStatus(
              transactionId,
              this.selectedPaymentMethod
            );
          } else if (this.selectedPaymentMethod === "wechat") {
            // Handle WeChat Pay by displaying the QR code
            this.showWeChatQRCode(response.data.codeUrl);
            this.pollPaymentStatus(
              transactionId,
              this.selectedPaymentMethod
            ); // Start polling payment status
          }
        })
        .catch((error) => {
          console.error("初始化支付失败:", error);
        });
    },
    showWeChatQRCode(codeUrl) {
      // Set the selected QR code URL to be used in the modal
      this.qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${encodeURIComponent(
        codeUrl
      )}`;

      // Show the QR code modal
      this.showQRCodeModal = true;
      this.centerDialogVisible = false
    },
    pollPaymentStatus(transactionId,selectedPaymentMethod) {
      console.log("Selected Payment Method:", selectedPaymentMethod);
      const username = localStorage.getItem("username");
      this.paymentPollingInterval = setInterval(() => {
        // const endpoint = "/api/pay/query"
        const endpoint =
          selectedPaymentMethod === "alipay"
            ? "/api/pay/query"
            : "/api/wechatpay_query";
        //selectedPaymentMethod === "alipay"
        //? "/api/pay/query"
        // : "/api/wechatpay_query";
        console.log("endpoint:", endpoint);
        console.log("transactionId:", transactionId);
        console.log("username:", username);
        axios
          .post(endpoint, { order_id: transactionId, username: username })
          .then((response) => {
            console.log("支付状态更新成功:", response.data);
            const status = response.data.trade_status;
            if (status === "TRADE_SUCCESS") {
              clearInterval(this.paymentPollingInterval);
              // this.refreshAccountStatus();
              this.showNotification("支付成功！感谢您的购买。", "success");
              if (selectedPaymentMethod === "wechat") {
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            } else if (status === "TRADE_FAIL") {
              clearInterval(this.paymentPollingInterval);
              this.showNotification("支付失败，请检查您的支付信息。", "error");
            }
          })
          .catch((error) => {
            console.error("支付状态更新失败:", error);
            clearInterval(this.paymentPollingInterval);
            this.showNotification(
              "支付状态获取失败，请检查网络连接或稍后重试。",
              "error"
            );
          });
      }, 2000); 
    },
    showNotification(message, type) {
      const notification = document.createElement("div");
      notification.className = `notification ${type}`;
      notification.textContent = message;
      document.body.appendChild(notification);
      setTimeout(() => {
        document.body.removeChild(notification);
      }, 5000);
    },
    selectPaymentMethod(method) {
      this.selectedPaymentMethod = method;
    },
    handleLogout() {
      localStorage.removeItem("auth");
      localStorage.removeItem("username");
      this.$router.push("/login");
    },
    fetchUserInfo() {
      axios
        .get(`/api/user/${this.username}`)
        .then((response) => {
          this.form = response.data;
          this.avatarUrl = response.data.avatarUrl || this.avatarUrl;
            this.remainNormalToken = response.data.remainNormalToken;
            this.remainAdvanceToken = response.data.remainAdvanceToken;
          this.form.language = response.data.language;
        })
        .catch((error) => {
          console.error("Error fetching user info:", error);
        });
    },
    handleLanguageChange(newLanguage) {
      this.form.language = newLanguage; // 更新语言设置
      console.log(newLanguage);
      // this.languageDisplay = this.form.language; // 如果你有一个计算属性或方法来处理显示
    },
    fetchUserLanguage() {
      axios
        .get(`/api/get_language/${this.username}`)
        .then((response) => {
          if (response.data.languag) {
            if (response.data.languag === "中文") {
              this.language = "zh-CN";
            } else {
              this.language = "en-US";
            }
          }
          console.log("1" + this.language);

          localStorage.setItem("language", this.language); // Update local storage when fetched
        })
        .catch((error) => {
          console.error("Failed to fetch language setting:", error);
        });
    },
    saveSettings() {
      axios
        .post("/api/update_language", {
          language: this.language,
          username: this.username,
        })
        .then((response) => {
          localStorage.setItem("language", this.language); // Update local storage on successful save
          console.log("2" + this.language);
          this.$message.success("语言设置已保存,请刷新页面");
          this.$bus.$emit("language-changed", this.language); // 广播语言更改事件
        })
        .catch((error) => {
          console.error("Failed to save language setting:", error);
          this.$message.error("保存语言设置失败");
        });
    },
    resetSettings() {
      localStorage.removeItem("language");
      this.language = "zh-CN"; // Reset to default Chinese
      this.$message.success("语言设置已重置");
      this.saveSettings();
    },
    onLanguageChange(newLanguage) {
      // console.log(newLanguage)
      // this.language = newLanguage;
    },
  },
  mounted() {
    this.fetchUserInfo();
    this.fetchUserLanguage();
    this.$bus.$on("language-changed", this.handleLanguageChange);
  },
  beforeDestroy() {
    this.$bus.$off("language-changed", this.handleLanguageChange);
  },
};
</script>
<style scoped>
.el-dialog__wrapper ::v-deep .el-dialog {
  background-color: white;
  border-radius: 10px;
  text-align: left;
}
.el-dialog__wrapper ::v-deep .el-dialog__header {
  border-bottom: 1px solid #ccc;
}

.payment-info {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.payment-label {
  flex: 0 0 auto;
  font-weight: bold;
  color: #333;
  margin-right: 10px; /* Reduced margin to minimize gap */
}

.payment-amount,
.payment-icons {
  flex: 1; /* Adjusts to take up remaining space */
  display: flex;
  align-items: center;
}

.payment-icons {
  justify-content: flex-start;
}

.payment-option {
  cursor: pointer;
  padding: 5px;
  margin-left: 10px; /* Adjusted for minimal spacing */
  border: 2px solid transparent;
  border-radius: 5px;
  transition: border-color 0.3s;
}
.payment-option img {
  width: 100px;
  height: 50px;
}

.payment-option.selected {
  border-color: #409eff; /* Highlight color */
}

.payment-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal-content {
  display: flex;
  flex-direction: column; /* 子元素垂直排列 */
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  width: 350px;
}

.modal-title {
  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;
}

.divider {
  border: none;
  border-top: 1px solid #ccc;
  margin-bottom: 20px;
}
.qr-code-container {
  margin: 20px 0; /* 调整上下边距 */
  text-align: center; /* 居中 QR 码 */
}


.qr-code-container img {
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
}

.qr-code-container .close-qr-code {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.qr-code-container .close-qr-code:hover {
  background-color: #555;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modal-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-buttons button:first-child {
  background-color: #409eff;
  color: white;
}

.modal-buttons button:last-child {
  background-color: #f0f0f0;
  color: #333;
}
</style>
